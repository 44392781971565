import { createTheme, darken, lighten } from "@mui/material";

declare module '@mui/material/styles' {
  interface Palette {
    sticker: Palette['primary'];
    favorite: Palette['primary'];
    primaryShades: {[token:string]: string};
  }
  interface PaletteOptions {
    sticker?: PaletteOptions['primary'];
    favorite?: PaletteOptions['primary'];
    primaryShades?: {[token:string]: string};
  }
}

export const ThemeDefaults = {
  PrimaryColor: "#7951E0",
  FavoriteColor: "#7951E0",
  StickerColor: "linear-gradient(93deg, #7D7CF9 1.8%, #C621E5 99.27%)",
  primaryShades: {
    "50": "#F8F6FE",
    "100": "#E4DCF9",
    "200": "#CABAF3",
    "300": "#AF97ED",
    "400": "#9574E7",
    "500": "#7951E0",
    "600": "#5F2FDA",
    "700": "#4E22BF",
    "800": "#401C9C",
    "900": "#321579"
  }
}
export const ColorAlphaCoefficient = 0.5;

//=========================================================
// DEFAULT THEME
//=========================================================

export const defaultTheme = createTheme({
  palette: {
    action: {
      disabledOpacity: 0.5,
    },
    background: {
      default: '#F9F9F9',
    },
    error: {
      main: '#F44040',
    },
    favorite: {
      main: ThemeDefaults.FavoriteColor,
      light: lighten(ThemeDefaults.FavoriteColor, ColorAlphaCoefficient),
      dark: darken(ThemeDefaults.FavoriteColor, ColorAlphaCoefficient),
    },
    primary: {
      main: ThemeDefaults.PrimaryColor,
      light: lighten(ThemeDefaults.PrimaryColor, ColorAlphaCoefficient),
      dark: darken(ThemeDefaults.PrimaryColor, ColorAlphaCoefficient),
    },
    sticker: {
      main: ThemeDefaults.StickerColor,
    },
    primaryShades: {
      ...ThemeDefaults.primaryShades,
    }
  },
  typography: {
    fontFamily: 'Inter,Roboto,Helvetica,Arial,sans-serif',
  }
});