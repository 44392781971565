import { Box, Tabs, Tab, useTheme, Typography, Button, CircularProgress } from "@mui/material";
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { makeStyle } from './myBets.style';
import { RootState } from "src/store/root.reducer";
import SVGFilterTickets from "src/assets/icons/SVGFilterTickets";
import SVGEmptyBasket from "src/assets/icons/SVGEmptyBasket";
// Actions
import * as ticketActions from 'src/store/actions/ticket.actions';
import { loadTaxes } from "src/store/actions/taxes.actions";
// Component
import BetItem from "src/component/ticketing/myBets/betItem.comp";
import dayjs from "dayjs";
import { IPageable } from "src/models/core/pagination.model";
import { IBetTicket } from "src/models/ticketing/ticket.model";

const MyBetsPage = () => {

  const TAB_PENDING = 0, TAB_PAST = 1;
  const DEF_TICKET_RENTENTION_DAYS = 7;
  const DEF_WAITING_TICKET_RENTENTION_DAYS = 60;
  const PAGE_SIZE = 10;

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>()
  const contentRef = useRef<any>();

  //====================================== States

  const gameId = useSelector((state: RootState) => state.game.gameId);
  const isBetListFetching = useSelector((state: RootState) => state.tickets.isFetching);
  const activeBetPList = useSelector((state: RootState) => state.tickets.activeBetPList);
  const pastBetPList = useSelector((state: RootState) => state.tickets.pastBetPList);
  const gamesAppConfig = useSelector((state: RootState) => state.app.config.games);

  const [currentTab, setCurrentTab] = useState(TAB_PENDING);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [ticketRetentionInDays, setTicketRetentionInDays] = useState(DEF_TICKET_RENTENTION_DAYS);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [displayBetPList, setDisplayBetPList] = useState<IPageable<IBetTicket>>(activeBetPList);

  const tabList = ['MyBets.pending', 'MyBets.past'];

  //====================================== Effects

  useEffect(() => {
    dispatch(loadTaxes());
  }, [])
  
  useEffect(() => {
    const betPList = currentTab == TAB_PENDING ? activeBetPList : pastBetPList;
    setDisplayBetPList(betPList);
  }, [currentTab, activeBetPList, pastBetPList])
  
  useEffect(() => {
    getPlayerBets();
    
    contentRef.current.scrollTop = 0;
    setScrollPosition(0);
  }, [currentTab])
  
  useEffect(() => {
    if (displayBetPList) {
      setShowMoreButton(displayBetPList.totalElements > displayBetPList.content?.length);
      if (scrollPosition > 0) {
        contentRef.current.scrollTop = scrollPosition;
        setScrollPosition(0);
      }
    }
  }, [displayBetPList])
  
  useEffect(() => {
    setTicketRetentionInDays(gamesAppConfig[gameId]?.ticketRetentionInDays || DEF_TICKET_RENTENTION_DAYS);
  }, [gamesAppConfig])


  //====================================== Functions

  const onShowMoreResults = () => {
    setScrollPosition(contentRef?.current?.scrollTop || 0);
    const _pageNumber = pageNumber + 1;
    getPlayerBets(_pageNumber);
  }

  const getPlayerBets = (_pageNumber = 0) => {

    setPageNumber(_pageNumber);

    const waitingBets = currentTab === TAB_PENDING;
    const dayCount = waitingBets ? DEF_WAITING_TICKET_RENTENTION_DAYS : ticketRetentionInDays;
    const createdAfter = dayjs(dayjs().format('YYYY-MM-DD')).add(-dayCount, 'day').toISOString();
    const sort = `expectedSettlementDate,${waitingBets ? "asc" : "desc"}`;
    if (_pageNumber > 0)
      dispatch(ticketActions.getMorePlayerBets(waitingBets, createdAfter, PAGE_SIZE, _pageNumber, sort));
    else
      dispatch(ticketActions.getPlayerBets(waitingBets, createdAfter, PAGE_SIZE, sort));
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  //====================================== Render

  const renderShowMoreButton = () => (
    isBetListFetching ?
      renderLoadingSpinner()
      :
      <Button
        sx={style.showMoreButton}
        disableRipple
        onClick={onShowMoreResults}
      >
        <Typography sx={style.showMoreButtonText}>
          {t("Ticket.show_more_results")}
        </Typography>
      </Button>
  )

  const renderLoadingSpinner = () => (
    <Box sx={style.loaderBox}>
      <CircularProgress sx={{ color: theme.palette.primary.main }} size={24} />
    </Box>
  )

  //------
  return (
    <Box id="my-bets" sx={style.container}>

      <Box sx={style.header}>
        <Tabs sx={style.tabs} value={currentTab} onChange={handleTabChange}>
          {tabList.map((tabKey, index) => (
            <Tab
              key={index}
              sx={[style.tab, currentTab === index && style.tabSelected]}
              disableRipple
              label={<Typography sx={[style.tabText, currentTab === index && style.tabTextSelected]}>{t(tabKey)}</Typography>}
            />
          ))}
        </Tabs>
      </Box>

      <Box sx={style.content} ref={contentRef}>

        {/* {currentTab === TAB_PAST && displayBetPList?.content?.length != 0 &&
          <Box sx={style.boxFilter}>
            <SVGFilterTickets />
            <Typography sx={style.boxFilterTxt}>{t('MyBets.filter')}</Typography>
          </Box>
        } */}

        <Box sx={style.listContainer}>

          {isBetListFetching &&
            renderLoadingSpinner()
          }

          {(isBetListFetching == false || pageNumber > 0) &&
            <>
              {displayBetPList?.content?.length == 0 &&
                <Box sx={style.ticketsEmpty}>
                  <SVGEmptyBasket />
                  <Typography sx={style.ticketsEmptyTxt}>{t('Ticket.empty_tickets')}</Typography>
                </Box>
              }

              {displayBetPList?.content?.map((bet, index) => (
                <BetItem key={index} item={bet} index={index} />
              ))}

              {showMoreButton &&
                renderShowMoreButton()
              }
            </>
          }
        </Box>
      </Box>

    </Box>
  )
}

export default MyBetsPage;