import { Box, Button, Grid, Typography, useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/root.reducer";
import { useTranslation } from "react-i18next";
import { makeStyle } from "./basket.style";

import * as basketActions from "../../../store/actions/basket.actions";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import * as bookingActions from "src/store/actions/booking.actions";
import * as PlayerActions from 'src/store/actions/player.actions';
import BasketItem from "src/component/lottery/basket/basketItem.comp";
import { EBasketStatus, IBasketItem } from "src/models/lottery/basket.model";
import { routePath } from "src/routes";
import commonStyle from "src/common/style/commonStyle";
import { useEffect, useState } from "react";
import { loadTaxes } from "src/store/actions/taxes.actions";
import ConfirmPopup from "src/component/common/confirmPopup/confirmPopup.comp";
import AlertPopup from "src/component/common/alertPopup/alertPopup.comp";
import SVGEmptyBasket from "src/assets/icons/SVGEmptyBasket";

const BasketPage = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const theme = useTheme();
  const style = makeStyle(theme);
  const isDesktop: boolean = useMediaQuery(theme.breakpoints.up('md'));

  //====================================== States

  const basket = useSelector((state: RootState) => state.basket);
  const itemList: IBasketItem[] = useSelector((state: RootState) => state.basket.itemList);
  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  const playerTotalBalance: number = useSelector((state: RootState) => state.player.playerInfo.totalBalance);
  const playerDataFetching: boolean = useSelector((state: RootState) => state.player.isFetching);

  const isValid = useSelector((state: RootState) => state.basket.isValid);

  const [indexItemToRemove, setIndexItemToRemove] = useState(-1);
  const [openAlertPopup, setOpenAlertPopup] = useState(false);

  //====================================== Effects

  useEffect(() => {
    dispatch(loadTaxes());
  }, [])

  //====================================== Functions

  const editBasketItem = (item: IBasketItem) => {
    dispatch(betBuilderActions.set_edition_mode({enabled: true, betId: item.betId}))
    const reconstructedSelectedSession = {
      [item.draw.drawId]: {
      ...item.draw
    }}
    dispatch(betBuilderActions.set_sessions_selected(reconstructedSelectedSession));
    navigate(routePath.betBuilder, { state: { betId: item?.betId } });
  }

  const onRemoveItemClick = (index: number) => {
    if (itemList[index].status == EBasketStatus.valid) {
      //user confirmation required
      setIndexItemToRemove(index);
    } else {
      doRemoveItem(index);
    }
  }
  
  const doRemoveItem = (index: number) => {
    const itemCountAfterRemoval = itemList.length - 1;
    dispatch(basketActions.removeItem(itemList[index]));
    setIndexItemToRemove(-1);
    if (itemCountAfterRemoval <= 0) {
      dispatch(betBuilderActions.reset_data());
      navigate(routePath.betBuilder);
    }
  }
  const dismissRemoveItem = () => {
    setIndexItemToRemove(-1);
  }

  const addItem = () => {
    dispatch(betBuilderActions.reset_data());
    navigate(routePath.betBuilder);
  }

  const validateBasket = () => {
    //-- Final checks
    const lastBasketStatus = checkBasket();
    if (lastBasketStatus != EBasketStatus.valid) {
      return;
    }
    const callback = (success: boolean) => {
      if (success) {
        //-- Check if player balance handle the totalStake
        if (playerTotalBalance < basket.totalStake) {
          setOpenAlertPopup(true);
          return;
        }
        //-- Create the booking
        navigate(routePath.bookingSubmit);
        dispatch(bookingActions.createBetBooking())
      } else {
        navigate(routePath.error)
      }
    }
    // Reload GetPlayerInfo to update player balance
    dispatch(PlayerActions.getPlayerInfo(callback))
  }

  const checkBasket = (): EBasketStatus => {
    return dispatch(basketActions.refresh());
  }

  //====================================== Render

  return (
    <Box id="basket" sx={style.container}>

      <Box sx={style.basketHeader}>
        <Typography sx={style.basketTitle}>
          {t('Basket.basket')}
        </Typography>
        <Button sx={style.basketHeaderAddButton} onClick={addItem}>
          <Typography sx={style.basketHeaderAddButtonText}>
            {t('Basket.add_bet')}
          </Typography>
        </Button>
      </Box>

      {itemList.length === 0 && 
        <Box sx={style.basketEmpty}>
          <SVGEmptyBasket />
          <Typography sx={style.basketEmptyTxt}>{t('Basket.empty_basket')}</Typography>
        </Box>
      }

      {itemList.length > 0 && 
        <Grid container sx={style.body}>
          {/* Basket Items */}
          <Grid item xs={12} md={6} lg={4} sx={style.basketItemList}>
            <Box sx={commonStyle.leftScrollContent}>
              {itemList?.map((item, index) => (
                item && <BasketItem
                  key={index}
                  index={index}
                  item={item}
                  onEditItem={() => editBasketItem(item)}
                  onRemoveItem={() => onRemoveItemClick(index)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Box sx={style.validateContainer}>
              {isDesktop &&
                <Box sx={style.totalPrice}>
                  <Typography sx={style.totalPriceLabel}>
                    {t('Basket.total_of')}
                    <Typography sx={[style.totalPriceLabel, style.totalPriceStrongLabel]}>
                      {t('Basket.basket').toLowerCase()}
                    </Typography>
                  </Typography>
                  <Typography sx={style.totalPriceValue}>
                    {basket.totalStake.toLocaleString()} {currency}
                  </Typography>
                </Box>
              }
              <Box sx={style.validateRow}>
                <Button
                  sx={style.validateButton}
                  onClick={validateBasket}
                  disabled={!isValid || playerDataFetching}
                  disableRipple
                >
                  {playerDataFetching && <CircularProgress sx={{color: '#FFF'}} size={20}/>}
                  <Typography sx={style.validateButtonText}>
                    {t('Basket.validate')}
                  </Typography>
                  <Typography sx={style.validateButtonSubText}>
                    Total&nbsp;
                    {basket.totalStake.toLocaleString()}
                    &nbsp;{currency}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      }

      <ConfirmPopup
        open={indexItemToRemove >= 0}
        onDismiss={dismissRemoveItem}
        title={t("Basket.remove_item_confirm")}
        dismissButtonText={t('cancel')}
        confirmButtonText={t('confirm')}
        onConfirm={() => doRemoveItem(indexItemToRemove)}
        type="warning"
      >
        {t("Basket.remove_item_confirm_desc")}
      </ConfirmPopup>

      <AlertPopup
        open={openAlertPopup}
        onClose={() => setOpenAlertPopup(false)}
        buttonText={t('understood')}
        title={t("Basket.insufficient_credits", { N: `${basket.totalStake} ${currency}` })}
        type="warning"
      >
        {t("Basket.insufficient_credits_desc")}
      </AlertPopup>

    </Box>
  )
}

export default BasketPage;