import { Box, Typography, useTheme, Popover, Button } from '@mui/material';
import { makeStyle } from './alertPopup.style';
import { ReactNode } from 'react';
import SVGCross from 'src/assets/icons/SVGCross';
import SVGInfo from 'src/assets/icons/SVGInfo';
import SVGWarning from 'src/assets/icons/SVGWarning';
import SVGQuestionCircle from 'src/assets/icons/SVGQuestionCircle';

type Props = {
  onClose: () => void;
  open: boolean;
  buttonText?: string;
  children: ReactNode;
  hideBackdrop?: boolean;
  type: "info" | "warning" | "error" | "question" | "alert"
  title?: string;
}

function AlertPopup({
  onClose,
  open,
  buttonText,
  children,
  hideBackdrop,
  title,
  type,
}: Props): JSX.Element {

  const style = makeStyle(useTheme());


  const renderIconContainer = (): JSX.Element => {
    if (type == "info") {
      return (
        <Box sx={style.iconContainer("#F8F6FE")}>
          <SVGInfo height={24} width={24} color={'#7951E0'} />
        </Box>
      )
    } else if (type == "warning") {
      return (
        <Box sx={style.iconContainer("#FEF3F2")}>
          <SVGWarning height={24} width={24} color={'#F04438'} />
        </Box>
      )
    } else if (type == "question") {
      return (
        <Box sx={style.iconContainer("#F8F6FE")}>
          <SVGQuestionCircle height={24} width={24} color={'#7951E0'} />
        </Box>
      )
    } else if (type == "alert") {
      return (
        <Box sx={style.iconContainer("#FFFAEB")}>
          <SVGInfo height={24} width={24} color={'#F5B400'} />
        </Box>
      )
    }
  };


  return (
    <Popover
      open={open}
      onClose={onClose}
      hideBackdrop={hideBackdrop ?? true}
      disableScrollLock={true}
      anchorReference={"none"}
      sx={style.popover}
    >
      <Box sx={style.container}>
        <Box sx={style.header}>
          {renderIconContainer()}
          <Box sx={style.closeButton} onClick={onClose}>
            <SVGCross width={28} height={28} color='#98A2B3' />
          </Box>
        </Box>
        <Box sx={style.content}>
          {title &&
            <Typography sx={style.title}>
              {title}
            </Typography>
          }
          <Typography sx={style.popupContentText}>
            {children}
          </Typography>
        </Box>
        <Box sx={style.boxButton}>
          <Button
            variant="contained"
            disableRipple
            disableElevation
            sx={style.button}
            onClick={onClose}
          >
            {buttonText || 'OK'}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default AlertPopup;
