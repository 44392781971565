import * as service from "../services/booking.service";
import { RootStateFn } from "../root.reducer";
import { IBooking, IBookingBetContent, IBookingBetDetails } from "src/models/ticketing/booking.model";
import { v4 as uuidv4 } from 'uuid';
import { StorageKeys } from "src/models/core/storage.model";
import { sleep } from "src/common/utils/dom.utils";

// Definition of the actions name

export const ACTIONS = {
  BOOKING_ERROR: 'BOOKING_ERROR',
  BOOKING_FETCHING: 'BOOKING_FETCHING',
  BOOKING_FETCHING_DONE: 'BOOKING_FETCHING_DONE',
  UPDATE_CURRENT_BOOKING: 'UPDATE_CURRENT_BOOKING',
  CLEAR_STATUS: "CLEAR_STATUS"
}

// Lifecycle
export const set_state_error = (err: any) => {
  return { type: ACTIONS.BOOKING_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.BOOKING_FETCHING };
}
export const set_state_fetching_done = (r) => {
  return { type: ACTIONS.BOOKING_FETCHING_DONE, payload: r };
}
export const set_current_booking = (r) => {
  return { type: ACTIONS.UPDATE_CURRENT_BOOKING, payload: r };
}
export const set_clear_status = () => {
  return { type: ACTIONS.CLEAR_STATUS };
}

// GAMES

export const createBetBooking = () => {
  return async (dispatch: any) => {
    try {
      dispatch(set_state_fetching());
      const basketData: IBooking = dispatch(fromBasketToBooking());
      let r = await service.createBetBooking(basketData);
      if (r.data.bookingId) {
        dispatch(set_state_fetching_done(r.data));
        await sleep(1500);
        let rStatus = await service.getBookingStatus(r.data.bookingId);
        while (service.isStatusPending(rStatus.data.status)) {
          await sleep(2500);
          rStatus = await service.getBookingStatus(r.data.bookingId)
        }
        dispatch(set_current_booking(rStatus.data));
      } else {
        throw new Error("Booking error");
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

// Functions (service)
export const fromBasketToBooking = () => {
  return (dispatch, getState: RootStateFn): IBooking => {
    const { basket, player, game } = getState();
    const bcToken = localStorage.getItem(StorageKeys.bCtoken)

    const listOfBets: IBookingBetDetails[] = basket.itemList.map((item, index) => ({
      order: index + 1,
      betCategory: 'lotteryInstantStandard',
      price: item.price,
      betTypeId: +item.betTypeId,
      listOfBetGrids: item.selectionListByGrid.map((grid, index) => ({
        gridName: item.selectedBetType.listOfGrids[index].gridName,
        listOfLottoNumbers: grid,
        usedAdditionalNumbers: grid.length - item.selectedBetType.listOfGridParameters[index].requiredNumber,
      })),
      stake: item.stake,
      numberOfDraws: item.nbConsecutiveDraws,
      drawId: +item.draw.drawId
    }));

    const betContentItem: IBookingBetContent = {
      ticketPrice: basket.totalStake,
      listOfBets,
      distribution: "paper",
      payment: {
        mode: "external"
      },
      playerReference: "",
      externalReference: "",
    }

    const toBookingItem: IBooking = {
      reference: uuidv4(),
      betContent: JSON.stringify(betContentItem),
      price: basket.totalStake,
      currency: player.playerInfo.currencyId,
      userId: player.playerInfo.userID,
      gameId: game.gameId,
      token: bcToken,
    }

    return toBookingItem;
  }
}