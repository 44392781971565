export enum EErrorType {
  _none, technical,
  no_session,
  session_expired,
  booking_failed,
  booking_failed_refund_success,
  booking_failed_refund_failed,
}

export interface IErrorExtraData {
  gameTicketReference?: string,
  transactionId?: string,
}