import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { Box, Typography, useTheme } from "@mui/material";
import { makeStyle } from "./gridPermutationStatus.style";
import { IBetType } from "src/models/lottery/betType.models";
import { EGridPermutationMode, IGridSelection, TGridSelectionsObj } from "src/models/lottery/grid.model";
import SVGQuestionCircle from "src/assets/icons/SVGQuestionCircle";
import { computeCombinations } from "src/common/utils/math.utils";
import AlertPopup from "src/component/common/alertPopup/alertPopup.comp";
import * as betBuilderActions from 'src/store/actions/betBuilder.actions';

const GridPermutationStatus = () => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();

  //====================================== States

  const [permutationMode, setPermutationMode] = useState(EGridPermutationMode.none);
  const [openPopup_permutationInfo, setOpenPopup_permutationInfo] = useState(false);


  const [mainSelectedGrid, setMainSelectedGrid] = useState<IGridSelection>();
  const [combinationEquivalenceText, setCombinationEquivalenceText] = useState('');

  const selectedBetType: IBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const selectedGrids: TGridSelectionsObj = useSelector((state: RootState) => state.betBuilder.selectedGrids);


  //====================================== Effects

  useEffect(() => {
    const mainGridName = selectedBetType.listOfGrids[0].gridName;
    const _grid = selectedGrids[mainGridName];
    setMainSelectedGrid(_grid);

  }, [selectedGrids, selectedBetType])

  useEffect(() => {
    checkPermutationMode();
    computeCombinationEquivalenceText();

  }, [mainSelectedGrid])

  //====================================== Functions

  const checkPermutationMode = () => {
    let mode = EGridPermutationMode.none;
    if (mainSelectedGrid) {
      const { requiredNumber, selectedNumbers } = mainSelectedGrid;
      if (selectedNumbers?.length > requiredNumber) {
        mode = EGridPermutationMode.enabled;
        const combinationCount = computeCombinations(selectedNumbers.length || 0, mainSelectedGrid.requiredNumber);
        dispatch(betBuilderActions.set_permutation({ isActivated: true, combinationCount }))
      }
      else {
        const allowsAdditionalNumbers = selectedBetType?.listOfGridParameters[0]?.maxAdditional > 0;
        mode = allowsAdditionalNumbers ? EGridPermutationMode.available : EGridPermutationMode.none;
        dispatch(betBuilderActions.set_permutation({ isActivated: false, combinationCount: 1 }))
      }
    }
    setPermutationMode(mode);
  }

  const showPermutationInfoPopup = () => {
    setOpenPopup_permutationInfo(true);
  }
  const hidePermutationInfoPopup = () => {
    setOpenPopup_permutationInfo(false);
  }

  const computeCombinationEquivalenceText = () => {
    if (mainSelectedGrid) {
      const selectedNumbersCount = mainSelectedGrid.selectedNumbers.length || 0;
      const combinationCount = computeCombinations(selectedNumbersCount, mainSelectedGrid.requiredNumber);
      const message = `${selectedNumbersCount} ${t('numbers').toLowerCase()} = ${combinationCount} ${t('combinations').toLowerCase()}`;
      setCombinationEquivalenceText(message);
    }
  }

  //====================================== Render

  /** convert "a text with some <[important words]> to highlight" to JSX */
  const renderPermutationHelpText = (baseText: string): JSX.Element => (
    <Typography sx={style.permutationInfoPopupText}>
      {baseText.split(/[<>]/).map((text, i) => {
        return (
          text.startsWith('[')) ?
          <Typography sx={style.permutationInfoPopupStrongText} component={'label'} key={i}>
            {text.replace(/[[\]]/g, '')}
          </Typography>
          : text
      })}
    </Typography>
  )


  return (
    <Box>
      {permutationMode == EGridPermutationMode.available &&
        <Box sx={style.permutationContainer}>
          <Typography sx={style.permutationText}>
            {t('Grid.permutation_mincount_1')}
            <Typography sx={style.permutationMinCountText} component={'label'}>
              {selectedBetType?.listOfGridParameters[0]?.requiredNumber + 1}
            </Typography>
            {t('Grid.permutation_mincount_2')}
            <Box sx={style.permutationInfoIcon} component={'label'} onClick={showPermutationInfoPopup}>
              <SVGQuestionCircle color="#00000066" />
            </Box>
          </Typography>
        </Box>
      }
      {permutationMode == EGridPermutationMode.enabled &&
        <Box sx={style.permutationContainer}>
          <Box sx={style.permutationEnabled}>
            <Typography sx={style.permutationEnabledText}>
              {t('Grid.permutation_enabled')}
            </Typography>
          </Box>
          <Box sx={style.permutationEnabled}>
            <Typography sx={style.permutationEnabledText}>
              {combinationEquivalenceText}
            </Typography>
          </Box>
        </Box>
      }

      <AlertPopup
        open={openPopup_permutationInfo}
        onClose={hidePermutationInfoPopup}
        buttonText={t("understood")}
        title={t("Grid.permutations")}
        type='question'
      >
        {renderPermutationHelpText(t('Grid.permutation_help_1'))}
        {renderPermutationHelpText(t('Grid.permutation_help_2'))}
      </AlertPopup>
    </Box>
  )
}

//======================================
export default GridPermutationStatus;