import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0, //scroll
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '48px',
      paddingRight: '48px',
      display: 'flex',
      alignItems: 'center'
    },
  },
  body: {
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
    },
  },
  header: {
    paddingLeft: '11px',
    paddingRight: '11px',
  },
  headerBox: {
    background: theme.palette.sticker.main,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    height: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '52px',
    },
  },
  headerText: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
  },
  ticketPriceRecap: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginLeft: '11px',
    marginRight: '11px',
    marginBottom: '8px'
  },
  ticketPriceRecapTxt1: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.40)'
  },
  ticketPriceRecapTxt2: {
    fontSize: 16,
    fontWeight: 700,
    color: '#000'
  },
  stakeErrorText: {
    marginTop: "12px",
    marginLeft: '11px',
    color: "#F52F2F",
    fontSize: 16,
    fontWeight: 500,
    maxWidth: '300px',
    marginBottom: '26px'
  },
  drawsSelectBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '11px',
    paddingRight: '11px',
    marginBottom: '32px',
    [theme.breakpoints.up('lg')]: {
      width: '300px'
    },
  },
  drawsSelectBtn: {
    borderRadius: '24px',
    backgroundColor: '#FFF',
    boxShadow: '0px 6px 12px 0px rgba(39, 39, 39, 0.08)',
    width: '100%',
    padding: 0
  },
  drawsSelectCount: {
    marginTop: '48px',
    marginBottom: '48px',
    color: '#272727',
    fontSize: 40,
    fontWeight: 700
  },
  resumeRow: {
    [theme.breakpoints.up('lg')]: {
      width: '300px',
      marginLeft: '11px'
    },
  },
  //Validate Button
  validateContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    marginBottom: '11px',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row'
    },
  },
  buttonRow: {
    marginLeft: '11px',
    marginRight: '11px',
    backgroundColor: 'transparent',
    textAlign: 'center',
    '&:first-of-type': { marginBottom: '8px' },
  },
  actionButton: {
    height: '48px',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '16px',
    [theme.breakpoints.up('md')]: {
      width: '300px',
    }
  },
  actionButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '16px',
    marginLeft: '8px',
  },
  validateButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: theme.palette.primary.light },
  },
  maxDrawButton: {
    backgroundColor: '#1D1D1D',
    borderRadius: '8px',
    marginBottom: '16px',
    '&:hover': { backgroundColor: '#1D1D1D' },
    '&:disabled': { backgroundColor: '#1D1D1D' },
  },
})