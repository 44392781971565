import { StorageKeys } from 'src/models/core/storage.model';
import { ENV } from '../../env';
import kp_axios from './api.koralplay.service';

//=============================================================================
// APP OPERATIONS
//=============================================================================

const PlayerTokenAutoRefreshDelay = 300 * 1000;
let _autoRefreshTimer: NodeJS.Timer;

export const startPlayerTokenAutoRefresh = (callback) => {
  _autoRefreshTimer = setInterval(() => callback(), PlayerTokenAutoRefreshDelay);
}

export const stopPlayerTokenAutoRefresh = () => {
  clearInterval(_autoRefreshTimer);
}


//=============================================================================
// API CALLS
//=============================================================================

const PROXY_URL = ENV.API_URL + '/provider-proxy/rest/v1/externalintegration';

export const getPlayerInfo = async () => {
  const token = localStorage.getItem(StorageKeys.bCtoken);
  return kp_axios.post(`${PROXY_URL}/getPlayerInfo `, { token });
}