import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    flex: 1,
    paddingLeft: '11px',
    paddingRight: '11px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%', //scroll
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '48px',
      paddingRight: '48px'
    },
  },
  body: {
    minHeight: 0, //scroll
    height: '100%',
  },
  //Header
  basketHeader: {
    background: theme.palette.sticker.main,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    minHeight: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '52px',
    },
  },
  basketHeaderAddButton: {
    padding: '8px',
    borderRadius: '12px',
    minWidth: '120px',
    background: '#FFF',
    "&:hover, &:active": {
      backgroundColor: '#FFF',
    },
  },
  basketHeaderAddButtonText: {
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'none',
    color: '#141414',
  },
  basketTitle: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
  },
  basketItemList: {
    [theme.breakpoints.up('md')]: {
      overflowY: 'auto',
      height: '100%',
      ...commonStyle.slimScrollBar(theme, 'left'),
    },
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '80px', // for the validate footer
    }
  },
  basketEmpty: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '40px'
  },
  basketEmptyTxt: {
    marginTop: '16px',
    fontSize: 16,
    fontWeight: 400,
    color: "#BBBBBB",
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  //Total Price
  totalPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '16px',
  },
  totalPriceLabel: {
    display: 'inline-flex',
    fontSize: '24px',
    fontWeight: 700,
  },
  totalPriceStrongLabel: {
    color: theme.palette.primary.main,
    paddingLeft: '4px',
  },
  totalPriceValue: {
    fontSize: '24px',
    fontWeight: 700,
  },
  //Validate
  validateContainer: {
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      paddingTop: '24px',
      maxWidth: '402px',
    }
  },
  validateRow: {
    margin: '16px',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
  },
  validateButton: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: theme.palette.primary.light },
    minHeight: '48px',
    padding: 0,
    width: '100%',
    maxWidth: '500px',
    borderRadius: '16px',
    textTransform: 'initial',
    gap: '10px'
  },
  validateButtonText: {
    color: '#FFF',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  validateButtonSubText: {
    color: '#FFF',
    fontWeight: 700,
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
})