import * as React from "react";

const SVGWarning = ({ color, width, height }: any) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9998 9.49999V13.5M11.9998 17.5H12.0098M10.6151 4.39171L2.39019 18.5983C1.93398 19.3863 1.70588 19.7803 1.73959 20.1037C1.769 20.3857 1.91677 20.642 2.14613 20.8088C2.40908 21 2.86435 21 3.77487 21H20.2246C21.1352 21 21.5904 21 21.8534 20.8088C22.0827 20.642 22.2305 20.3857 22.2599 20.1037C22.2936 19.7803 22.0655 19.3863 21.6093 18.5983L13.3844 4.39171C12.9299 3.60654 12.7026 3.21396 12.4061 3.08211C12.1474 2.9671 11.8521 2.9671 11.5935 3.08211C11.2969 3.21396 11.0696 3.60655 10.6151 4.39171Z" stroke={color || '#1D1D1D'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default SVGWarning;
