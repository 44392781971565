import { Box, Button, Grid, Typography, useTheme, Collapse } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "src/component/core/backButton/backButton.comp";
import GameRulesSlider from "src/component/lottery/gameRules/gameRulesSlider.comp";
import Paytable from "src/component/lottery/paytable/Paytable.comp";
import { routePath } from "src/routes";
import { makeStyle } from "./betTypeRules.style";
import { findSlides } from "src/store/services/gameRules.service";
import { EGameRuleType, IGameRuleSlide } from "src/models/lottery/gameRules.model";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { IBetType } from "src/models/lottery/betType.models";
import SVGArrowDown from "src/assets/icons/SVGArrowDown";

enum Tabs { rules, gains }

const BetTypeRulesPage = () => {

  const { betTypeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const style = makeStyle(theme);

  //====================================== States

  const gameRuleAllSlides: IGameRuleSlide[] = useSelector((state: RootState) => state.gameRules.allSlides);
  const betTypeList: IBetType[] = useSelector((state: RootState) => state.game.betTypesActive);
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);
  const betTypeListByCategory = useSelector((state: RootState) => state.game.betTypesByCategory);

  const [activeTab, setActiveTab] = useState(Tabs.rules);
  const [showTabs, setShowTabs] = useState(false);
  const [showBackButton, setShowBackButton] = useState(true);
  const [currentBetTypeId, setCurrentBetTypeId] = useState<number>()
  const [currentBetType, setCurrentBetType] = useState<IBetType>()
  const [betTypeCollapse, setBetTypeCollapse] = useState(false)
  const [currentCatList, setCurrentCatList] = useState<IBetType[]>()

  //====================================== Effects

  useEffect(() => {
    if (betTypesActive && betTypeId) {
      setCurrentBetTypeId(+betTypeId);
      setCurrentBetType(betTypesActive.find(bt => bt.betTypeId === betTypeId))
    }
  }, [betTypeId])

  useEffect(() => {
    if (currentBetType) {
      setCurrentCatList(betTypeListByCategory.find(btCat => btCat.category === currentBetType.category)?.betTypes)
    }
  }, [currentBetType])

  useEffect(() => {
    if (currentBetTypeId && betTypeList.length > 0) {
      const rules: IGameRuleSlide[] = findSlides(gameRuleAllSlides, { groupType: EGameRuleType.betType, groupId: currentBetTypeId });
      setShowTabs(rules.length > 0);
      setActiveTab(activeTab === Tabs.rules && rules.length > 0 ? Tabs.rules : Tabs.gains);

      const game_description_rules = findSlides(gameRuleAllSlides, { groupType: EGameRuleType.game, groupId: betTypeList[0].gameId });
      setShowBackButton(betTypeList.length > 1 || game_description_rules.length > 0);
    }
  }, [currentBetTypeId, betTypeList, gameRuleAllSlides])

  //====================================== Functions

  const onTabClick = (tab: number) => {
    setActiveTab(tab);
  }

  const onGoBack = () => {
    navigate(routePath.info)
  }

  const onBetTypeSelect = (betType: IBetType) => {
    setCurrentBetType(betType);
    setCurrentBetTypeId(+betType.betTypeId);
    setBetTypeCollapse(false)
  }

  //====================================== Render

  return (
    <Box id="bet-type-rules" sx={style.container}>

      {
        showBackButton &&
        <BackButton onClick={onGoBack} />
      }

      {currentCatList?.length > 1 && 
        <Grid container sx={style.betTypeCollapseBox}>
          <Button
            disableRipple
            sx={style.betTypeCollapse}
            onClick={() => setBetTypeCollapse(!betTypeCollapse)}
          >
            <Typography sx={style.betTypeCollapseText}>
              {currentBetType?.name}
            </Typography>
            {betTypesActive.length > 1 && 
              <Box sx={[style.groupArrowIcon, betTypeCollapse && style.groupArrowIconOpen]}>
                <SVGArrowDown width="24" height="24" color="#FFF" />
              </Box>
            }
          </Button>
          <Collapse in={betTypeCollapse} timeout="auto" sx={style.betTypeButtonBox}>
            {currentCatList?.map((betType, key) => (
              <Button
                key={key}
                disableRipple
                sx={style.betTypeButton}
                onClick={() => onBetTypeSelect(betType)}
              >
                <Typography sx={style.betTypeButtonText}>{betType?.name}</Typography>
              </Button>
            )
          )}
          </Collapse>
        </Grid>
      }

      {showTabs &&
        <Grid container spacing={2} sx={style.tabButtonBar}>
          <Grid item xs={6} lg={2}>
            <Button
              sx={[style.tabButton, activeTab == Tabs.rules && style.tabButtonSelected]}
              onClick={() => onTabClick(Tabs.rules)}
            >
              <Typography sx={[style.tabButtonText, activeTab == Tabs.rules && style.tabButtonTextSelected]}>
                {t('GameRules.rules')}
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={6} lg={2}>
            <Button
              sx={[style.tabButton, activeTab == Tabs.gains && style.tabButtonSelected]}
              onClick={() => onTabClick(Tabs.gains)}
            >
              <Typography sx={[style.tabButtonText, activeTab == Tabs.gains && style.tabButtonTextSelected]}>
                {t('GameRules.max_gain')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      }

        {activeTab == Tabs.rules &&
          <GameRulesSlider betTypeId={currentBetTypeId?.toString()} />
        }
        {activeTab == Tabs.gains &&
          <Paytable currentBetType={currentBetType} />
        }

    </Box>
  )
}

export default BetTypeRulesPage;