import * as service from "../services/ticket.service";
import { RootStateFn } from "../root.reducer";
import { timeZone } from "src/common/i18n/i18n";
import { IBetTicket, IPlayerBetsRequest } from "src/models/ticketing/ticket.model";
import { IPageable } from "src/models/core/pagination.model";
import { Game } from "src/models/lottery/game.model";
import { IDraw, TDrawsAsObject } from "src/models/lottery/draw.model";

//--------
// Definition of the action names
export const ACTIONS = {
  //TICKETS
  TICKETS_ERROR: 'TICKETS_ERROR',
  TICKETS_FETCHING: 'TICKETS_FETCHING',
  TICKETS_ACTIVE_PLAYER_BETS: 'TICKETS_ACTIVE_PLAYER_BETS',
  TICKETS_PAST_PLAYER_BETS: 'TICKETS_PAST_PLAYER_BETS',
  //DRAW RESULTS
  TICKETS_DRAW_RESULTS: 'TICKETS_DRAW_RESULTS',
  TICKETS_DRAWS_RESULTS_FETCHING: 'TICKETS_DRAWS_RESULTS_FETCHING',
  TICKETS_DRAWS_RESULTS_FETCHING_DONE: 'TICKETS_DRAWS_RESULTS_FETCHING_DONE',
}

//--------
// Lifecycle
//TICKETS
export const set_state_error = (err: any) => {
  return { type: ACTIONS.TICKETS_ERROR, payload: err };
}
export const set_state_fetching = () => {
  return { type: ACTIONS.TICKETS_FETCHING };
}
export const set_state_active_player_bets = (betPList: IPageable<IBetTicket>) => {
  return { type: ACTIONS.TICKETS_ACTIVE_PLAYER_BETS, payload: betPList };
}
export const set_state_past_player_bets = (betPList: IPageable<IBetTicket>) => {
  return { type: ACTIONS.TICKETS_PAST_PLAYER_BETS, payload: betPList };
}
//DRAW RESULTS
export const set_drawResults = (data: TDrawsAsObject) => {
  return { type: ACTIONS.TICKETS_DRAW_RESULTS, payload: data };
}
export const set_state_drawResults_fetching = () => {
  return { type: ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING };
}
export const set_state_drawResults_fetching_done = () => {
  return { type: ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING_DONE };
}

//--------
// Actions

//-- BET LIST

export const getPlayerBets = (waitingBets: boolean, createdAfter: string, size: number, sort: string) => {
  return getPlayerBets_internal(waitingBets, createdAfter, size, /*pageNumber=*/0, sort, /*keepExisting=*/false);
}

export const getMorePlayerBets = (waitingBets: boolean, createdAfter: string, size: number, pageNumber: number, sort: string) => {
  return getPlayerBets_internal(waitingBets, createdAfter, size, pageNumber, sort, /*keepExisting=*/true);
}

const getPlayerBets_internal = (waitingBets: boolean, createdAfter: string, size: number, pageNumber: number, sort: string, keepExisting: boolean) => {
  return async (dispatch: any, getState: RootStateFn) => {
    try {
      const ticketStore = getState().tickets;
      const gameId = getState().game.gameId;
      const gameType = getState().game.game.gameType;
      const userId = getState().player.playerInfo.userID;

      //-- Get Bets
      const params: IPlayerBetsRequest = {
        createdAfter,
        gameId,
        page: pageNumber,
        size,
        sort,
        timeZone,
        userId,
        waitingBets,
      }
      dispatch(set_state_fetching());
      const r = await service.getPlayerBets(params)
      const newBetPList: IPageable<IBetTicket> = r.data;
      newBetPList.content = newBetPList.content || [];

      let allBetPList = newBetPList;
      if (keepExisting) {
        allBetPList = waitingBets ? { ...ticketStore.activeBetPList } : { ...ticketStore.pastBetPList };
        allBetPList.content = [...allBetPList.content, ...newBetPList.content];
      }
      if (waitingBets) {
        dispatch(set_state_active_player_bets(allBetPList));
      } else {
        dispatch(set_state_past_player_bets(allBetPList));
      }

      //-- Get missing Draw Results
      if (waitingBets == false) {
        const drawIdSet = new Set<string>();
        newBetPList.content?.forEach(bet => {
          bet.betDetails.listOfStatusAndDraws.forEach(E => {
            drawIdSet.add(E.draw.drawId);
          })
        })
        const currDrawResults = getState().tickets.drawResults;
        const newDrawIdList = Array.from(drawIdSet).filter(drawId => !(drawId in currDrawResults));
        if (newDrawIdList.length > 0) {
          dispatch(getMoreDrawResults(gameId, gameType, newDrawIdList)); //async/deferred call
        }
      }
    } catch (err) {
      dispatch(set_state_error(err));
    }
  }
}

//-- DRAW RESULTS

export const getMoreDrawResults = (gameId: number, gameType: Game.GameTypeEnum, drawIdList: string[]) => {
  return getDrawResults(gameId, gameType, drawIdList, /*keepExisting=*/true);
}

export const getDrawResults = (gameId: number, gameType: Game.GameTypeEnum, drawIdList: string[], keepExisting: boolean) => {
  return async (dispatch: any, getState: RootStateFn) => {
    try {
      dispatch(set_state_drawResults_fetching());
      
      const r = (gameType == Game.GameTypeEnum.MutualLottery)
        ? await service.getMutualDrawResults(gameId, drawIdList)
        : await service.getInstantDrawResults(drawIdList);

      const drawList: IDraw[] = r?.data?.draws || [];
      let oDrawResults: TDrawsAsObject = drawList.reduce((acc, cur) => ({ ...acc, [cur.drawId]: cur }), {});
      if (keepExisting) {
        const currDrawResults = getState().tickets.drawResults;
        oDrawResults = { ...oDrawResults, ...currDrawResults };
      }
      dispatch(set_drawResults(oDrawResults));

    } catch (err) {
      dispatch(set_state_drawResults_fetching_done());
    }
  }
}

