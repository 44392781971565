import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  boxButton: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse",
    },
  },
  button: {
    flex: 1,
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    paddingLeft: '10px',
    paddingRight: '10px',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '8px',
    '& :hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "flex-start",
    flexDirection: "row",
  },
  iconContainer: (color) => ({
    backgroundColor: color,
    padding: "10px",
    borderRadius: "50%",
    height: "24px",
    width: "24px",
  }),
  title: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontWeight: 700,
  },
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(43 43 43 / 50%)',
    backdropFilter: 'blur(8px)',
    '& .MuiPaper-root': {
      borderRadius: '8px',
      flexDirection: 'row',
      justifyContent: 'center',
      margin: '16px',
      maxWidth: '400px',
      width: '80%',
      padding: '20px',
      textAlign: 'left',
    }
  },
  container: {
    display: 'flex',
    gap: "16px",
    flexDirection: "column"
  },
  content: {
    display: 'flex',
    gap: "4px",
    flexDirection: "column"
  },
  closeButton: {
    padding: "8px"
  },
  popupContentText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#878787',
  },
});