import { useEffect, useState } from "react";
import { Box, useTheme, Typography, Button, Tooltip, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { IBetTicket, EBetSettlementStatus, EBetPaymentStatus } from "src/models/ticketing/ticket.model";
import { makeStyle } from "./betItem.style";
import { useTranslation } from "react-i18next";
import SVGChevronDown from "src/assets/icons/SVGChevronDown";
import dayjs from "dayjs";
import { IBetType } from "src/models/lottery/betType.models";
import { zeroFill } from "src/common/utils/format.utils";
import { computeCombinations } from "src/common/utils/math.utils";
import { Game } from "src/models/lottery/game.model";
import SupportPopup from "src/component/core/supportPopup/supportPopup.comp";
import { computeNetAmount } from "src/store/services/taxes.service";
import { IGridDrawn, TDrawsAsObject } from "src/models/lottery/draw.model";
import SVGInfo from "src/assets/icons/SVGInfo";
import AlertPopup from "src/component/common/alertPopup/alertPopup.comp";
import i18n from "src/common/i18n/i18n";

type Props = {
  item: IBetTicket,
  index: number
}

const BetItem = ({ item, index }: Props): JSX.Element => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const { betDetails } = item;
  const taxesInfo = useSelector((state: RootState) => state.app.config.support?.taxes?.info);
  const [translatedTaxesText, setTranslatedTaxesText] = useState<string>("");

  //====================================== States

  const [showDetails, setShowDetails] = useState(false);
  const [betTypeData, setBetTypeData] = useState<IBetType>();
  const [maxPotentialAward, setMaxPotentialAward] = useState<number>(0);
  const [combinationCount, setCombinationCount] = useState<number>(0);
  const [errorTextKey, setErrorTextKey] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openSupportPopup, setOpenSupportPopup] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);
  const [listOfGridDrawn, setListOfGridDrawn] = useState<IGridDrawn[]>([]);
  const [isDrawPast, setIsDrawPast] = useState(false);
  const [showTaxAmount, setShowTaxAmount] = useState<boolean>(false);
  const [showTaxesPopup, setShowTaxesPopup] = useState<boolean>(false);

  const isMobile = useSelector((state: RootState) => state.app.isMobile);
  //
  const betTypesActive = useSelector((state: RootState) => state.game.betTypesActive);
  const gameName = useSelector((state: RootState) => state.game.game.name);
  const betTypes = useSelector((state: RootState) => state.game.betTypes);
  //
  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  //
  const useTaxes: boolean = useSelector((state: RootState) => state.taxes.useTaxes);
  const taxLevels = useSelector((state: RootState) => state.taxes.taxLevels);
  //
  const drawResults: TDrawsAsObject = useSelector((state: RootState) => state.tickets.drawResults);

  //====================================== Effects

  useEffect(() => {
    //Check for payment errors
    updateErrorTextKey();
  }, [item])

  useEffect(() => {
    item && setIsDrawPast(item.settlementStatus != EBetSettlementStatus.WAIT);
  }, [item])

  useEffect(() => {
    // Get the betType associated to the bet
    const _betTypeData = betTypes?.find(betType => +betType.betTypeId == betDetails.betTypeId);
    setBetTypeData(_betTypeData)
  }, [betTypes]);

  useEffect(() => {
    // compute combinations
    if (betTypes && betTypeData) {
      setCombinationCount(
        computeCombinations(
          betDetails.listOfBetGrids[0].listOfLottoNumbers.length,
          betTypeData?.listOfGridParameters[0]?.requiredNumber
        )
      )
    }
  }, [betTypeData])

  useEffect(() => {
    // Compute max potential award
    let _potentialAward = 0;
    if (betTypeData && item.betDetails.gameType == Game.GameTypeEnum.InstantLottery) {
      const maxStakeMultiplier = Math.max(...betTypeData.listOfPayLines.map(E => E.gainValue));
      _potentialAward = item.price * maxStakeMultiplier;
      setMaxPotentialAward(_potentialAward)
    }
  }, [betTypeData])

  useEffect(() => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN:
        setShowTaxAmount(useTaxes);
        setTaxAmount(item.wonAmount ? item.wonAmount - computeNetAmount(item.wonAmount, taxLevels) : 0);
        break;
      case EBetSettlementStatus.WAIT:
        setTaxAmount(maxPotentialAward - computeNetAmount(maxPotentialAward, taxLevels));
        setShowTaxAmount(useTaxes);
        break;
      default:
        setShowTaxAmount(false);
    }
  }, [useTaxes, taxLevels, maxPotentialAward, item.settlementStatus])

  useEffect(() => {
    //Note: 1 online bet = 1 draw
    const drawId = item.betDetails.listOfStatusAndDraws[0]?.draw?.drawId;
    const _itemDrawResult = drawResults[drawId];
    setListOfGridDrawn(_itemDrawResult?.listOfGridDrawn || []);
  }, [drawResults, item])

  useEffect(() => {
    setTranslatedTaxesText(taxesInfo[i18n.language])
  }, [])

  //====================================== Functions

  const updateErrorTextKey = () => {
    //Note: we assume PAYMENT_REQUESTED is an error state, as the payment usually takes only few seconds.
    let _errorTextKey = '';
    if (item.paymentStatus == EBetPaymentStatus.PAYMENT_BLOCKED
      || item.paymentStatus == EBetPaymentStatus.PAYMENT_REQUESTED) {
      switch (item.settlementStatus) {
        case EBetSettlementStatus.CANCELED_GAME:
        case EBetSettlementStatus.CANCELED_TICKET:
          _errorTextKey = 'Ticket.canceled_payment_blocked_error';
          break;
        case EBetSettlementStatus.WIN:
          _errorTextKey = 'Ticket.win_payment_blocked_error';
          break;
      }
    }
    setErrorTextKey(_errorTextKey);
  }

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  }

  const openTaxesPopup = () => {
    setShowTaxesPopup(true)
  }

  const computeNumberBoxExtraStyle = (numb: number, winNumbs: number[], item: IBetTicket) => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN:
        return winNumbs?.includes(numb) && style.selectionNumberBoxWin;
      case EBetSettlementStatus.LOST:
        return winNumbs?.includes(numb) ? {} : style.selectionNumberBoxLost;
      default:
        return {};
    }
  }

  const computeItemBorderStyle = (item: IBetTicket) => {
    switch (item.paymentStatus) {
      case EBetPaymentStatus.PAYMENT_SUCCESS:
        return style.containerSuccess;
      case EBetPaymentStatus.PAYMENT_BLOCKED:
      case EBetPaymentStatus.PAYMENT_EXPIRED:
      case EBetPaymentStatus.PAYMENT_PENDING_PLAYER:
      case EBetPaymentStatus.PAYMENT_REQUESTED:
        return style.containerError;
      case EBetPaymentStatus.PAYMENT_NOT_INITIATED:
      case EBetPaymentStatus.PAYMENT_NOT_NEEDED:
        return {};
      default:
        return {};
    }
  }

  const computeStatusBoxStyle = (item: IBetTicket) => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN:
        return style.statusContainerWon;
      case EBetSettlementStatus.LOST:
        return style.statusContainerLost;
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET:
        return style.statusContainerCanceled;
      default:
        return {}
    }
  }

  //====================================== Render

  const renderBetStatusText = () => {
    switch (item.settlementStatus) {
      case EBetSettlementStatus.WIN: return (
        <Typography sx={[style.statusText, style.statusTextWon]}>
          {t(`Ticket.won`)}: {(item.wonAmount || 0).toLocaleString()} {currency}
        </Typography>
      );
      case EBetSettlementStatus.LOST: return (
        <Typography sx={[style.statusText, style.statusTextLost]}>
          {t(`Ticket.lost`)}
        </Typography>
      );
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET: return (
        <Typography sx={[style.statusText, style.statusTextCanceled]}>
          {t(`Ticket.canceled`)}
        </Typography>
      );
      default: return (
        <Typography sx={style.statusText}>
          {t(`Ticket.wait`)}
        </Typography>
      );
    }
  }

  const renderDetailsLineStrong = () => {
    if (item.settlementStatus == EBetSettlementStatus.LOST) {
      return <></>;
    }
    let labelKey = '';
    let value = '';
    switch (item.settlementStatus) {
      case EBetSettlementStatus.CANCELED_GAME:
      case EBetSettlementStatus.CANCELED_TICKET:
        labelKey = 'refunded';
        value = betDetails.price.toLocaleString();
        break;
      case EBetSettlementStatus.WAIT:
        labelKey = 'Bet.max_gain';
        value = maxPotentialAward.toLocaleString();
        break;
      case EBetSettlementStatus.WIN:
        labelKey = 'gain';
        value = (item.wonAmount || 0).toLocaleString();
        break;
      default:
        break;
    }
    return (
      <Box sx={[style.detailsLine, style.detailsLineStrong]}>
        <Typography sx={style.detailsLineStrongLabel}>
          {t(labelKey)}
        </Typography>
        <Typography sx={[style.detailsLineValue, style.detailsLineStrongValue]}>
          {value}&nbsp;{currency}
        </Typography>
      </Box>
    )
  }

  return (
    <Box key={index} sx={[style.container, computeItemBorderStyle(item)]}>

      <Box sx={[style.statusContainer, computeStatusBoxStyle(item)]}>
        {renderBetStatusText()}
      </Box>

      {/* HEADER */}
      <Box sx={style.header}>
        <Box sx={style.headerLeft}>
          <Typography sx={style.title}>
            {betTypesActive?.length == 1 ? gameName : betTypeData?.name}
          </Typography>
          <Typography sx={style.date}>
            {dayjs(item.expectedSettlementDate).format('DD/MM/YY - HH:mm')}
          </Typography>
        </Box>
      </Box>

      {/* SELECTION */}
      <Box sx={style.selectionContainer}>

        <Box sx={style.selectionTitle}>
          <Typography sx={style.selectionTitleText}>
            {betDetails.listOfBetGrids[0].listOfLottoNumbers.length} {t(betDetails.listOfBetGrids[0].listOfLottoNumbers.length > 1 ? 'numbers' : 'number').toLowerCase()}
            {betDetails.listOfBetGrids[0].usedAdditionalNumbers >= 1 &&
              <Typography sx={style.selectionTitleText} component="label">
                ,&nbsp;
                {combinationCount} {t('combinations').toLowerCase()}*
              </Typography>
            }
          </Typography>
          {betDetails.listOfBetGrids[0].usedAdditionalNumbers >= 1 &&
            <Typography sx={style.selectionTitleSubText}>
              *{t('Bet.active_permutation')}
            </Typography>
          }
        </Box>



        <Box sx={style.selectionNumberList}>
          {betDetails.listOfBetGrids[0].listOfLottoNumbers.map((numb, key) => (
            <Box
              key={key + '.' + key}
              sx={[style.selectionNumberBox, computeNumberBoxExtraStyle(numb, listOfGridDrawn[0]?.winningNumbers, item)]}
            >
              <Typography sx={style.selectionNumberText}>
                {zeroFill(numb)}
              </Typography>
            </Box>
          ))}
        </Box>

        {/* Draw results */}
        {isDrawPast &&
          <>
            <Box sx={[style.selectionTitle, { marginTop: '16px' }]}>
              <Typography sx={style.selectionTitleText}>
                {t('Ticket.draw_results')}
              </Typography>
            </Box>
            <Box sx={style.selectionNumberList}>
              {listOfGridDrawn.length == 0 &&
                <CircularProgress size={'30px'} sx={{ color: '#bbb' }} />
              }
              {listOfGridDrawn[0]?.winningNumbers.map(numb => (
                <Box key={numb} sx={style.selectionNumberBox}>
                  <Typography sx={style.selectionNumberText}>
                    {zeroFill(numb)}
                  </Typography>
                </Box>
              ))}
              {listOfGridDrawn.length > 1 && listOfGridDrawn[1].winningNumbers.map(numb => (
                <Box key={numb} sx={style.selectionNumberExtraGridBox}>
                  <Typography sx={style.selectionNumberText}>
                    {zeroFill(numb)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        }

      </Box>

      <Box sx={style.detailsContainer}>
        <Button
          sx={style.detailsContainerExpandButton}
          onClick={toggleDetails}
          disableRipple
        >
          <Typography sx={style.detailsContainerExpandButtonText}>
            {t('Bet.details')}
          </Typography>
          <Box sx={[style.detailsContainerIcon, showDetails && style.detailsContainerIconOpen]}>
            <SVGChevronDown width="12" height="12" color="#FFF" />
          </Box>
        </Button>
        {showDetails &&
          <>
            <Box sx={style.detailsContent}>
              <Box sx={style.detailsLineList}>
                <Box sx={style.detailsLine}>
                  <Typography sx={style.detailsLineLabel}>
                    {t('stake')}
                  </Typography>
                  <Typography sx={style.detailsLineValue}>
                    {betDetails.stake.toLocaleString()} {currency}
                  </Typography>
                </Box>
                <Box sx={style.detailsLine}>
                  <Typography sx={style.detailsLineLabel}>
                    {t('Ticket.totalCost')}
                  </Typography>
                  <Typography sx={style.detailsLineValue}>
                    {betDetails.price.toLocaleString()} {currency}
                  </Typography>
                </Box>
                {showTaxAmount &&
                  <Box sx={style.detailsLine}>
                    <Box sx={style.detailsBoxTaxLabel}>
                      <Typography sx={style.detailsLineTaxLabel}>
                        {t('tax')}
                      </Typography>
                      <Button disableRipple onClick={openTaxesPopup} sx={style.detailsTaxButton}>
                        <SVGInfo color="#F79009" width="16" height="16" />
                      </Button>
                    </Box>
                    <Typography sx={[style.detailsLineValue, style.detailsLineTaxValue]}>
                      {taxAmount == 0 ? 0 : (-taxAmount).toLocaleString()} {currency}
                    </Typography>
                  </Box>
                }
                <Box sx={style.detailsLine}>
                  <Typography sx={style.detailsLineLabel}>
                    {t('Ticket.refTicket')}
                  </Typography>
                  <Tooltip
                    title={item.gameTicketReference}
                    arrow
                    placement="top-end"
                    open={openTooltip && isMobile}
                    PopperProps={{ sx: style.toolTip }}
                  >
                    <Button disableRipple sx={style.buttonTooltip} onClick={() => setOpenTooltip(!openTooltip)}>
                      <Typography noWrap={true} sx={[style.detailsLineValue, { color: "#878787", fontSize: '12px', textTransform: 'none' }]}>
                        {item.gameTicketReference}
                      </Typography>
                    </Button>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
            {renderDetailsLineStrong()}
          </>
        }
      </Box>

      {errorTextKey.length > 0 &&
        <>
          <Typography sx={style.errorText}>
            *{t(errorTextKey)}
          </Typography>
          <Button onClick={() => setOpenSupportPopup(true)} disableRipple sx={style.errorButton}>
            <Typography sx={style.errorButtonTxt}>
              {t('Ticket.contact_support')}
            </Typography>
          </Button>
        </>
      }

      <SupportPopup
        open={openSupportPopup}
        onClose={() => setOpenSupportPopup(false)}
        buttonText={t("understood")}
        gameTicketReference={item.gameTicketReference}
        transactionId={item.wonTransactionId || item.refundTransactionId}
      />

      <AlertPopup
        open={showTaxesPopup}
        onClose={() => setShowTaxesPopup(false)}
        buttonText={t("understood")}
        type="alert"
        title={t("tax")}
      >
        {translatedTaxesText}

      </AlertPopup>

    </Box>
  );
}

export default BetItem;
