import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  selectionNumberList: {
    display: 'flex',
    justifyContent: 'flex-start',
    rowGap: "10px",
    flexWrap: 'wrap',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
  selectionNumberContainer: {
    [theme.breakpoints.down('md')]: {
      boxShadow: '0px 2px 6px 0px #00000014',
      backgroundColor: '#FFFF',
      padding: "10px",
      borderRadius: '50px',
      display: "flex",
      height: 'min-content',
      margin: "auto",
      width: "90%",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row-reverse",
    },
  },
  slotButton: {
    width: 40,
    height: 40,
    minWidth: '40px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: "10px",
    backgroundColor: '#E5E5E5',
  },
  slotButtonFilled: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
  slotButtonText: {
    color: '#fff',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '18px',
    textAlign: "center"
  },
  slotButtonFilledText: {
    fontSize: 16,
    margin: "5px"
  },
  flashButton: {
    backgroundColor: '#272727',
    '&:hover': { backgroundColor: '#272727' },
    borderRadius: '12px',
    width: '200px',
    height: '40px',
    [theme.breakpoints.down('md')]: {
      minWidth: '40px',
      width: '40px',
      borderRadius: '50%',
    },
  },
  flashButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '18px',
    marginLeft: '8px',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
})