import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { makeStyle } from "./selectedNumbers.style";
import { IBetType } from "src/models/lottery/betType.models";
import SVGFlash from "src/assets/icons/SVGFlash";
import { sortNumber, zeroFill } from "src/common/utils/format.utils";
import { IGridSelection, TGridSelectionsObj } from "src/models/lottery/grid.model";
import { sleep } from "src/common/utils/dom.utils";

type Props = {
  setIsBuildingFlashNumbers: (value: boolean) => void;
  isBuildingFlashNumbers: boolean;
}

const SelectedNumbers = ({
  isBuildingFlashNumbers,
  setIsBuildingFlashNumbers
}: Props) => {

  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();

  //====================================== States

  const [selectedMainGrid, setSelectedMainGrid] = useState<IGridSelection>(null);

  const selectedBetType: IBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const selectedGrids: TGridSelectionsObj = useSelector((state: RootState) => state.betBuilder.selectedGrids);
  const mainGridName = useSelector((state: RootState) => state.game.mainGridName)

  //====================================== Effects

  useEffect(() => {

    if (selectedGrids?.[mainGridName]) {
      setSelectedMainGrid(selectedGrids[mainGridName]);
    }

  }, [selectedGrids])


  //====================================== Functions

  const buildFlashNumbers = async () => {
    if (isBuildingFlashNumbers) {
      return;
    }
    setIsBuildingFlashNumbers(true);

    try {
      for (const elem of selectedBetType.listOfGridParameters) {
        // Reset the numbers 
        dispatch(betBuilderActions.updateSelectedGrid(elem.gridName, []));

        const currentGrid = selectedGrids[elem.gridName];
        const { listOfLottoNumbers, requiredNumber } = currentGrid;

        // random player numbers
        let randomNumbersSet = new Set<number>();
        while (randomNumbersSet.size < requiredNumber) {
          randomNumbersSet.add(listOfLottoNumbers[Math.floor(Math.random() * listOfLottoNumbers.length)]);
        }
        const selectedNumbers = [...randomNumbersSet];
        selectedNumbers.sort(sortNumber);

        for (const num of selectedNumbers) {
          await sleep(50);
          dispatch(betBuilderActions.updateSelectedGridFlash(elem.gridName, num));
        }
      }
    } finally {
      setIsBuildingFlashNumbers(false);
    }
  }

  const computeEmptySlotCount = () => {

    let selectionPossibilitiesNumber = 0

    if (!selectedMainGrid) {
      // Noting to do
    } else if (selectedMainGrid.requiredNumber < selectedMainGrid.selectedNumbers.length) {
      selectionPossibilitiesNumber = selectedMainGrid.maxAdditional + selectedMainGrid.requiredNumber - selectedMainGrid.selectedNumbers.length;
    } else {
      selectionPossibilitiesNumber = selectedMainGrid.requiredNumber - selectedMainGrid.selectedNumbers.length;
    }
    return selectionPossibilitiesNumber;

  }

  const removeNumberFromSelection = (numb: number) => {
    let tempNumbers = selectedMainGrid?.selectedNumbers.filter(E => E != numb);
    dispatch(betBuilderActions.updateSelectedGrid(mainGridName, tempNumbers));
  }

  //====================================== Render

  return (
    <Box sx={style.selectionNumberContainer}>
      <Button
        sx={style.flashButton}
        onClick={buildFlashNumbers}
        fullWidth
        disableRipple
      >
        <SVGFlash color='#FFF' />
        <Typography sx={style.flashButtonText}>
          Flash
        </Typography>
      </Button>
      <Box sx={style.selectionNumberList}>
        {selectedMainGrid && (
          <>
            {selectedMainGrid?.selectedNumbers?.map((numb, key) => (
              <Button
                key={key}
                sx={[style.slotButton, style.slotButtonFilled]}
                onClick={() => removeNumberFromSelection(numb)}
              >
                <Typography sx={[style.slotButtonText, style.slotButtonFilledText]}>
                  {zeroFill(numb)}
                </Typography>
              </Button>)
            )}
            {Array.from(Array(computeEmptySlotCount())).map((_, i) => (
              <Button
                key={i}
                sx={style.slotButton}
                disabled
              >
                <Typography sx={style.slotButtonText}>
                  ?
                </Typography>
              </Button>
            ))}
          </>
        )}

      </Box>
    </Box>

  )
}

//======================================
export default SelectedNumbers;