import { EBookingStatus, IBooking, IBookingState } from 'src/models/ticketing/booking.model';
import { ENV } from '../../env';
import kp_axios from './api.koralplay.service';
import { AxiosResponse } from 'axios';

//=============================================================================
// API CALLS
//=============================================================================

export const createBetBooking = async (params: IBooking) => {
  return kp_axios.post(`${ENV.API_URL}/betslipbooking/rest/v1/bookings/web-lottery`, params);
}

export const getBookingStatus = async (bookingId: string): Promise<AxiosResponse<IBookingState>> => {
  return kp_axios.get(`${ENV.API_URL}/betslipbooking/rest/v1/bookings/${bookingId}/status`);
}

//=============================================================================
// HELPERS
//=============================================================================


export const isStatusPending = (status: EBookingStatus) => {
  switch (status) {
    case EBookingStatus.GAME_CONTROL_PENDING:
    case EBookingStatus.BUY_PENDING:
    case EBookingStatus.PAYMENT_SUCCESS:
    case EBookingStatus.BET_REGISTRATION_PENDING:
      return true;
    default:
      return false;
  }
}