import ReactGA from "react-ga4";
import { ENV } from "src/env";

export type t_error_options = { category?: string, location?: string, err?: any, value?: any };

class AnalyticsService {

  private baseEventParams = {
    app_version: ENV.APP_VERSION,
  };
  private gtagOptions = {
    send_page_view: false, //disable automatic send
    ...this.baseEventParams,
  };
  private isInitialized = false;

  initialize() {
    if (this.isInitialized == false) {
      this.initAnalytics();
      this.initTagManager();
      this.isInitialized = true;
    }
  }

  private initAnalytics = () => {
    if (ENV.ANALYTICS_KEY) {
      ReactGA.initialize(
        ENV.ANALYTICS_KEY,
        { gtagOptions: this.gtagOptions }
      );
    }
  }

  private initTagManager() {
    //Feature enabled ?
    if (!ENV.TAGMANAGER_KEY) {
      return;
    }
    // Add GTM to the DOM
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    //Header
    const gtmScript = document.createElement('script');
    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${ENV.TAGMANAGER_KEY}`;
    document.head.insertBefore(gtmScript, document.head.firstChild);

    //Body
    const ifrm = document.createElement('iframe');
    ifrm.setAttribute('src', `//www.googletagmanager.com/ns.html?id=${ENV.TAGMANAGER_KEY}`);
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';
    const noscript = document.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);
    document.body.insertBefore(noscript, document.body.firstChild);
  }

  trackPage(path: string, title?: string) {
    ReactGA.send({
      hitType: 'pageview',
      page: path,
      title : title ?? path,
      ...this.baseEventParams
    });
  }

  trackEvent(eventName?: string, category?: string, eventParams?: { [key: string]: any }) {
    console.log(`[analytics] Tracking event: ${eventName}. ${category}.`, eventParams);
    const params = { ...this.baseEventParams, ...eventParams };
    ReactGA.event(eventName, params);
  }

  trackError(description: string, opts: t_error_options = {}) {
    //Note: - The `description` is truncated to 100 characters by GA => artificially extend to 200 chars by using extra param
    //      - The app_version may still not be registered if the error comes at app startup, so we force sending this
    //      - GA is not able to manage parameter values of type object => need to convert to a string

    let description2 = description.length > 100 ? '...' + description.substr(100) : undefined;

    if (opts.err && opts.err.message) {
      //case: err is of type 'Error': extract its message, we don't need the stacktrace.
      opts.err = opts.err.message;
    }
    if (typeof opts.err === 'object') {
      opts.err = JSON.stringify(opts.err);
    }

    const params = { description, description2, ...opts, ...this.baseEventParams };
    ReactGA.event('exception', params);

    // Log the error into the console
    console.error(((opts.location || opts.category) ? '[' + (opts.location || opts.category) + '] ' : '') + description, opts.err);
  }
}

//====================================== 
const Analytics = new AnalyticsService;
export default Analytics;