import { IPlayerInfo } from 'src/models/player/player.model';
import { ACTIONS } from '../actions/player.actions';

export interface IPlayerReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  // Player info
  playerInfo: IPlayerInfo;
  isConnected?: boolean;
}

const initialState = <IPlayerReducer>{
  error: false,
  isFetching: true,
  errorMessage: '',

  // Player info
  isConnected: false,
  playerInfo: {} as IPlayerInfo,
}


export default function playerReducer(state = initialState, action): IPlayerReducer {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.PLAYER_INFO_ERROR: {
      return {
        ...state,
        error: true,
        isFetching: false,
        isConnected: false,
        errorMessage: action.payload?.message
      }
    }
    case ACTIONS.PLAYER_INFO_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.PLAYER_INFO: {
      return {
        ...state,
        isConnected: true,
        playerInfo: {...action.payload},
      }
    }
    case ACTIONS.PLAYER_INFO_FETCHING_DONE: {
      return { ...state, isFetching: false, error: false }
    }
    default:
      return state
  }
}