export enum EBookingStatus {
  GAME_CONTROL_PENDING = "GAME_CONTROL_PENDING",
  GAME_CONTROL_FAILED = "GAME_CONTROL_FAILED",
  BUY_PENDING = 'BUY_PENDING',
  BET_REGISTRATION_PENDING = "BET_REGISTRATION_PENDING",
  BUY_FAILED = "BUY_FAILED",
  BOOKING_SUCCESS = "BOOKING_SUCCESS",
  REFUND_PENDING = "REFUND_PENDING",
  REFUND_FAILED = "REFUND_FAILED",
  REFUND_SUCCESS = "REFUND_SUCCESS",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
}

export interface IBooking {
  reference: string;
  betContent?: string;
  price: number;
  currency: string;
  userId: number;
  token: string;
  gameId: number;
}

export interface IPaymentMode {
  mode: string;
}

export interface IBookingBetGrid {
  gridName: string;
  listOfLottoNumbers: number[];
  usedAdditionalNumbers: number;
}

export interface IBookingBetContent {
  ticketPrice: number;
  listOfBets: IBookingBetDetails[];
  distribution: string;
  payment: IPaymentMode;
  playerReference: string;
  externalReference : string;
}

export interface IBookingBetDetails {
  order: number;
  betCategory: string;
  price: number;
  betTypeId: number;
  listOfBetGrids: IBookingBetGrid[],
  stake: number;
  numberOfDraws: number;
  drawId: number;
}

export interface IBookingResponse {
  bookingId: string;
  reference: string;
  creationDate: string;
  status: EBookingStatus;
  userId: string;
  price: number;
  currency: string;
}

export interface IBookingState {
  status: EBookingStatus;
  failedErrorCode?: number;
  failedErrorType?: string;
  failedReason?: string;
  refundTransactionId?: string;
}