import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  container: {
    padding: '16px',
    marginBottom: '16px',
    overflow: 'hidden',
    borderRadius: '24px',
    background: '#FFF',
    boxShadow: '0px 6px 12px 0px #27272714',
  },
  containerError: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: '14px',
    fontWeight: 600,
    paddingTop: '16px',
  },
  //===================================
  // HEADER
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
  },
  headerLeft: {

  },
  title: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#272727',
  },
  date: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#BBB',
  },
  headerRight: {
    textAlign: 'right',
  },
  headerActionButton: {
    width: '40px',
    minWidth: '40px',
  },
  //===================================
  // SELECTION
  selection: {
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    padding: '12px 16px',
  },
  selectionDrawCount: {
    background: '#272727',
    borderRadius: '8px',
    marginBottom: '8px',
    padding: '8px',
    width: 'fit-content',
  },
  selectionDrawCountText: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: 700,
  },
  selectionNumberList: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
  },
  selectionNumberBox: {
    width: 40,
    height: 40,
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: '8px',
    marginTop: '8px'
  },
  selectionNumberExtraGridBox: {
    backgroundColor: '#4682b4',
    marginLeft: '8px',
  },
  selectionNumberText: {
    fontWeight: 700,
    color: '#FFF',
    fontSize: 16,
  },
  selectionTitle: {

  },
  selectionTitleText: {
    color: '#333',
    fontSize: '14px',
    fontWeight: 600,
  },
  selectionTitleSubText: {
    fontSize: '12px',
    fontWeight: 700,
    color: '#BBB',
  },
  //===================================
  // DETAILS
  detailsContainer: {
    marginTop: '16px',
  },
  detailsContainerExpandButton: {
    maxHeight:"40px",
    padding: '12px',
    backgroundColor: '#272727',
    borderRadius: '32px',
    width: '100%',
    justifyContent: 'space-between',
    "&:hover, &:active": {
      backgroundColor: '#272727',
    },
  },
  detailsContainerExpandButtonText: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 600,
    paddingLeft: '8px',
  },
  detailsContainerIcon: {
    display: 'flex',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  detailsContainerIconOpen: {
    transform: 'rotate(180deg)',
  },
  detailsContent: {
    backgroundColor: '#F5F5F5',
    borderRadius: '8px',
    marginTop: '8px',
  },
  detailsLineList: {
    padding: '8px',
    paddingBottom: 0,
  },
  detailsLine: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '4px',
    marginBottom: '4px',
  },
  detailsLineLabel: {
    color: '#878787',
    fontSize: '16px',
  },
  detailsLineValue: {
    fontSize: '16px',
    fontWeight: 700,
  },
  detailsBoxTaxLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  detailsLineTaxLabel: {
    color: '#F79009',
    marginRight: '8px',
    fontSize: '16px'
  },
  detailsLineTaxValue: {
    color: '#F79009',
  },
  detailsTaxButton: {
    minWidth: '0px',
    padding: '0px',
  },
  detailsLineStrong: {
    backgroundColor: '#32CD32',
    padding: '12px 8px 12px 8px',
    borderRadius: '0px 0px 16px 16px',
  },
  detailsLineStrongLabel: {
    color: '#FFF',
    fontSize: '16px',
    fontWeight: 700,

  },
  detailsLineStrongValue: {
    color: '#FFF',
  },
})