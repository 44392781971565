import { AppEntryParams } from 'src/models/core/app.model';
import { ACTIONS } from '../actions/app.actions';
import { IAppConfig } from 'src/models/core/app-config.model';

export interface IAppReducer {
  // Metadata
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  // Data
  config: IAppConfig;
  entryParams: AppEntryParams,
  showExitButton: boolean,
  isMobile: boolean,
}

const initialState: IAppReducer = {
  entryParams: {} as AppEntryParams,
  showExitButton: false,
  isMobile: false,
  error: false,
  isFetching: true,
  errorMessage: '',
  config: {} as IAppConfig
}

// Reducer to catch the actions

export default function basketReducer(state = initialState, action): IAppReducer {
  switch (action.type) {
    //------------------
    case ACTIONS.APP_ENTRY_PARAMS: {
      const entryParams: AppEntryParams = action.payload ?? {};
      const showExitButton = entryParams?.exitUrl?.length > 0 && entryParams.isMobile == true;
      const isMobile = entryParams.isMobile == true;
      return {
        ...state,
        entryParams,
        showExitButton,
        isMobile
      }
    }
    case ACTIONS.APP_CONFIG_FAILED: {
      return {
        ...state,
        error: true,
        isFetching: false,
        errorMessage: action.payload?.message
      }
    }
    case ACTIONS.APP_CONFIG_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.APP_CONFIG_DATA: {
      return {
        ...state,
        isFetching: false,
        config: action.payload || {},
      }
    }
    default:
      return state
  }
}