import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as BetBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Button, Typography, useTheme, Grid, TextField, InputAdornment } from "@mui/material";
import { makeStyle } from "./stakePicker.style";
import ConfirmPopup from "src/component/common/confirmPopup/confirmPopup.comp";
import BetBuilderGridsSummary from "./betBuilderGridsSummary.comp";
import { isNullOrEmptyObject } from "src/common/utils/format.utils";
import { Game } from "src/models/lottery/game.model";

const StakePicker = ({
  goNextStep,
  goPreviousStep,
}) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch();

  //====================================== States

  const betBuilderData = useSelector((state: RootState) => state.betBuilder);
  const selectedBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const gameName = useSelector((state: RootState) => state.game.game.name);
  const selectedStake = useSelector((state: RootState) => state.betBuilder.selectedStake);
  const totalPrice = useSelector((state: RootState) => state.betBuilder.totalPrice);
  const maxGain = useSelector((state: RootState) => state.betBuilder.maxGain);
  const currency: string = useSelector((state: RootState) => state.player.playerInfo.currencyId);
  const gameType = useSelector((state: RootState) => state.game.game.gameType);
  const staggeredStake = useSelector((state: RootState) => state.app.config.betting.staggered_stake) || 1;


  const [stakeError, setStakeError] = useState('');
  const [totalPriceError, setTotalPriceError] = useState('');
  const [isAllowMultipleDrawsByBet, setAllowMultipleDrawsByBet] = useState<boolean>(false);
  const [useUniqueStake, setUseUniqueStake] = useState<boolean>(false);
  const [openResetPopup, setOpenResetPopup] = useState<boolean>(false);
  const [showTotalPriceSummary, setShowTotalPriceSummary] = useState<boolean>(false);
  const [customStakeAmount, setCustomStakeAmount] = useState(0);


  //====================================== Effects

  useEffect(() => {
    if (gameName && betBuilderData) {
      setUseUniqueStake(selectedBetType?.defaultPrices?.length == 1);
      setAllowMultipleDrawsByBet(selectedBetType?.maxDrawByBet > 1);
    }
  }, [gameName, betBuilderData])

  useEffect(() => {
    if (gameType == Game.GameTypeEnum.InstantLottery) {
      const _totalPrice = selectedStake * betBuilderData.selectedSessionCount * (betBuilderData.combinationCount || 1);
      const _maxGainValue = Math.max(...selectedBetType.listOfPayLines.map(elem => elem.gainValue));
      dispatch(BetBuilderActions.set_totalPrice(_totalPrice));
      dispatch(BetBuilderActions.set_maxGain(_totalPrice * _maxGainValue));
      checkStakeValid(selectedStake);
    }
    if (!selectedBetType.defaultPrices.includes(selectedStake)) {
      setCustomStakeAmount(betBuilderData.selectedStake);
    }
  }, [selectedStake])

  useEffect(() => {
    checkTotalPriceError();
  }, [betBuilderData.totalPrice]);

  useEffect(() => {
    const _showTotalPriceSummary = !isNullOrEmptyObject(betBuilderData.selectedSessions) && selectedStake > 0 && !useUniqueStake;
    setShowTotalPriceSummary(_showTotalPriceSummary);
  }, [betBuilderData, selectedStake, useUniqueStake]);

  //====================================== Functions

  const onSelectProposedAmount = (stake: number) => {
    dispatch(BetBuilderActions.set_stake_selected(stake));
    setCustomStakeAmount(0);
  }

  const onCustomAmountChange = ($event) => {
    const amount = parseInt($event.target.value);
    if (amount > selectedBetType.maxBetAmount * 10) {
      return; //limit input length
    }

    setCustomStakeAmount(amount);

    const _selectedStake = amount > 0 ? amount : selectedBetType.minBetAmount;
    dispatch(BetBuilderActions.set_stake_selected(_selectedStake));
  }

  const checkStakeValid = (stake: number) => {
    let _error = '';
    if (stake < selectedBetType.minBetAmount) {
      _error = t('Stake.error_amount_too_low', { N: selectedBetType.minBetAmount, currency });
    }
    else if (stake > selectedBetType.maxBetAmount) {
      _error = t('Stake.error_amount_too_high', { N: selectedBetType.maxBetAmount, currency });
    }
    else if (stake % staggeredStake != 0) {
      _error = t('Stake.error_amount_staggering', { N: staggeredStake });
    }
    setStakeError(_error);
  }

  const checkTotalPriceError = () => {
    let _error = '';
    if (totalPrice > selectedBetType?.maxBetsTotalPrice) {
      _error = t('Stake.error_total_price_exceeded', { N: selectedBetType.maxBetsTotalPrice.toLocaleString() + ' ' + currency });
    }
    setTotalPriceError(_error);
  }

  const onOpenResetPopup = () => {
    setOpenResetPopup(true)
  }

  const onCloseResetPopup = () => {
    setOpenResetPopup(false)
  }

  const resetBet = () => {
    dispatch(BetBuilderActions.reset_data());
  }

  const onValidateStake = () => {
    goNextStep()
  }


  //====================================== Render

  const renderProposedStakes = () => (
    <Grid container justifyContent='center'>
      {selectedBetType.defaultPrices.map((price, key) => (
        <Grid key={key} item sx={style.stakeBtnBox} xs={selectedBetType.defaultPrices.length % 2 == 0 ? 6 : 4}>
          <Button
            sx={[style.stakeBtn, price == selectedStake && style.stakeBtnSelected]}
            disableRipple
            onClick={() => onSelectProposedAmount(price)}
          >
            <Typography sx={[style.stakeBtnText, price == selectedStake && style.stakeBtnTextSelected]}>
              {price.toLocaleString()}
            </Typography>
            <Typography sx={[style.stakeBtnText, style.stakeBtnTextCurrency, price == selectedStake && style.stakeBtnTextSelected]}>
              {currency}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  )

  const renderCustomStakePicker = () => (
    <>
      <TextField
        type="text"
        inputMode="numeric"
        sx={[style.customAmountBtn, stakeError?.length > 0 && style.customAmountBtnInvalid]}
        variant="outlined"
        placeholder={t('Stake.personalized_amount')}
        onChange={onCustomAmountChange}
        onFocus={(event) => event.target.select()}
        value={customStakeAmount || ''}
        autoComplete="off"
        inputProps={{
          autoComplete: "off",
          max: 100000,
          pattern: "[0-9]+",
        }}
        InputProps={{
          autoComplete: "off",
          endAdornment: customStakeAmount > 0 ?
            <InputAdornment position="end"><Typography sx={{ color: '#FFF' }}>{currency}</Typography></InputAdornment>
            : undefined,
        }}
      />
      {stakeError &&
        <Typography sx={style.stakeErrorText}>
          *{stakeError}
        </Typography>
      }
      {totalPriceError && !stakeError &&
        <Typography sx={style.stakeErrorText}>
          *{totalPriceError}
        </Typography>
      }
    </>
  )

  const renderUniqueStakePicker = () => (
    <>
      <Button
        sx={style.customAmountBtn}
        disableRipple
      >
        <Typography sx={style.actionButtonText}>
          {`${t('stake')} ${selectedBetType.defaultPrices[0] * betBuilderData.selectedSessionCount} ${currency}`}
          {betBuilderData.selectedSessionCount > 1 && '*'}
        </Typography>
      </Button>

      <Box>
        <Typography sx={[style.uniqueStakeTxt, { marginBottom: '8px', marginTop: '24px' }]}>
          * {`${t('Stake.unit_price')} = ${selectedBetType.defaultPrices[0]} ${currency}`}
        </Typography>
        <Typography sx={style.uniqueStakeTxt}>
          * {`${betBuilderData.selectedSessionCount} ${t(betBuilderData.selectedSessionCount > 1 ? 'draws' : 'draw')}`}
        </Typography>
      </Box>
    </>
  )

  //--
  return (
    <Box id="stake-picker" sx={style.container}>
      <Grid container sx={style.header}>
        <Grid item xs={12}>
          <Box sx={style.headerBox}>
            <Typography sx={style.headerText}>
              {t('stakes')}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={style.body}>

        <Grid item xs={12} lg={6} sx={style.gridsSummaryContainer}>
          <BetBuilderGridsSummary />
        </Grid>

        <Grid item xs={12} lg={6} sx={style.stakeContainer}>

          <Box sx={style.stakePickerBox}>
            <Typography sx={style.stakePickerTitleText}>
              {t('Stake.title1')}
              <Typography sx={style.stakePickerTitleImportantText} component={'label'}>
                {t('Stake.title2')}
              </Typography>
            </Typography>

            {useUniqueStake ?
              renderUniqueStakePicker()
              : <>
                {renderProposedStakes()}
                {renderCustomStakePicker()}
              </>
            }
          </Box>

          {showTotalPriceSummary && !stakeError && !isAllowMultipleDrawsByBet &&
            <Box>
              <Box sx={style.ticketPriceRecap}>
                <Typography sx={style.ticketPriceRecapTxt1}>{t('Stake.ticket_price')}</Typography>
                <Typography sx={style.ticketPriceRecapTxt2}>{totalPrice.toLocaleString()} {currency}</Typography>
              </Box>
              <Box sx={style.maxGainBox}>
                <Typography sx={style.maxGainTxt}>
                  {t('Stake.max_price')}: {maxGain.toLocaleString()} {currency}
                </Typography>
              </Box>
            </Box>
          }

          <Grid sx={style.validateContainer}>
            <Grid item xs={12} lg={6} sx={style.buttonRow}>
              <Button
                sx={[style.actionButton, style.validateButton]}
                onClick={onValidateStake}
                disabled={!selectedStake || stakeError.length > 0 || totalPriceError.length > 0}
                disableRipple
              >
                <Typography sx={style.actionButtonText}>
                  {t('Stake.validate_stake')}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} sx={style.buttonRow}>
              <Button
                sx={[style.actionButton, style.deleteButton]}
                onClick={onOpenResetPopup}
                disableRipple
              >
                <Typography sx={style.actionButtonText}>
                  {t('Grid.delete_grid')}
                </Typography>
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Grid>

      <ConfirmPopup
        open={openResetPopup}
        onDismiss={onCloseResetPopup}
        title={t("Grid.delete_grid") + "?"}
        dismissButtonText={t('cancel')}
        confirmButtonText={t('clear')}
        onConfirm={resetBet}
        type="warning"
      >{t("Stake.reset_grid")}</ConfirmPopup>

    </Box>
  )
}

//======================================
export default StakePicker;