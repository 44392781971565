import { IPlayerBetsRequest } from 'src/models/ticketing/ticket.model';
import { ENV } from '../../env';
import kp_axios, { PLATFORM_LOTTERY } from './api.koralplay.service';

//=============================================================================
// API CALLS
//=============================================================================

const TICKETING_URL = ENV.API_URL + '/ticketing-reading/rest';
const ENGINE_URL = ENV.API_URL + ENV.ENGINE_PATH;

export const getPlayerBets = async (params: IPlayerBetsRequest) => {
  return kp_axios.get(`${TICKETING_URL}/v1/online-player/bets`, { params });
}

export const getInstantDrawResults = async (drawIdList: string[]) => {
  const body = { gameSessionIds: drawIdList }
  const params = { platform: PLATFORM_LOTTERY };
  return kp_axios.patch(`${ENGINE_URL}/v2-a/webplayer/games/lottery/instant/draws`, body, { params });
}

export const getMutualDrawResults = async (gameId: number, drawIdList: string[]) => {
  //TODO: api multi-tirage inexistante - en attendant, appel des api mono-tirages
  return Promise.all(
    drawIdList.map(drawId => kp_axios
      .get(`${ENGINE_URL}/v2/webplayer/games/${gameId}/lottery/mutual/draws/${drawId}`)
      .then(res => res.data))
  ).then(retList => ({ data: { success: true, draws: retList } }))
  // const body = { gameSessionIds: drawIdList }
  // const params = { platform: PLATFORM_LOTTERY };
  // return kp_axios.patch(`${ENGINE_URL}/v2-a/webplayer/games/lottery/mutual/draws`, body, { params });
}
