import { Box, CircularProgress, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { makeStyle } from "./betBuilder.style";
import { RootState } from "src/store/root.reducer";
import { useDispatch, useSelector } from "react-redux";
import * as gamesActions from "src/store/actions/game.action";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import * as basketActions from "src/store/actions/basket.actions";
import { BetBuilderSteps } from "src/models/lottery/betBuilder.model";
import { useNavigate, useLocation } from "react-router-dom";
import { routePath } from "../../../routes";
import SessionPicker from "src/component/lottery/betBuilder/sessionPicker/sessionPicker.comp";
import BetTypePicker from "src/component/lottery/betBuilder/betTypePicker/betTypePicker.comp";
import GridPicker from "src/component/lottery/betBuilder/gridPicker/gridPicker.comp";
import StakePicker from "src/component/lottery/betBuilder/stakePicker/stakePicker.comp";
import ConsecutiveDrawsPicker from "src/component/lottery/betBuilder/consecutiveDrawsPicker/consecutiveDrawsPicker.comp";
import BackButton from "src/component/core/backButton/backButton.comp";
import { scrollMainContentToTop } from "src/common/utils/dom.utils";
import { EErrorType } from "src/models/core/error.model";
import { loadTaxes } from "src/store/actions/taxes.actions";
import { IBasketItem } from "src/models/lottery/basket.model";
const BetBuilderPage = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const style = makeStyle(useTheme());

  //====================================== States

  // Local States

  const [isLoading, setLoading] = useState(true);
  const [hasAvailableSessions, setHasAvailableSessions] = useState(true);

  const [ignoreBetTypeStep, setIgnoreBetTypeStep] = useState(false);
  const [ignoreSessionStep, setIgnoreSessionStep] = useState(false);

  // Store states

  const currentGame = useSelector((state: RootState) => state.game.game);
  const betTypeList = useSelector((state: RootState) => state.game.betTypesActive);
  const currentSessions = useSelector((state: RootState) => state.game.currentSessions);

  const currentStep = useSelector((state: RootState) => state.betBuilder.currentStep);
  const firstStep = useSelector((state: RootState) => state.betBuilder.firstStep);
  const selectedBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const isEditionMode = useSelector((state: RootState) => state.betBuilder.isEditionMode);
  const betBuilder = useSelector((state: RootState) => state.betBuilder);
  const basketItemList: IBasketItem[] = useSelector((state: RootState) => state.basket.itemList);

  const isFetchingBetTypes = useSelector((state: RootState) => state.game.stateBetTypes.pending);
  const isFetchingSessions = useSelector((state: RootState) => state.game.stateCurrentSessions.pending);

  //====================================== Effects

  useEffect(() => {
    dispatch(loadTaxes());
  }, [])

  useEffect(() => {

    if (currentStep == BetBuilderSteps.NONE && !isEditionMode) {
      dispatch(betBuilderActions.reset_data());
      loadGameData();
    }
    else if (currentStep == BetBuilderSteps.INIT) {
      initializeSteps();
    }
  }, [currentStep])

  useEffect(() => {
    if (currentStep == BetBuilderSteps.NONE
      && currentGame
      && currentSessions?.length > 0
      && betTypeList.length > 0
    ) {
      // we have loaded all the betTypes & session data => can initialize steps
      setCurrentStep(BetBuilderSteps.INIT);
    }
    const _ignoreSessionStep = betTypeList[0].maxDrawByBet > 1;
    setIgnoreSessionStep(_ignoreSessionStep);
    const sessionBetTypeId = currentSessions?.[0]?.betTypeId;
    const _ignoreBetTypeStep = betTypeList.length == 1 || sessionBetTypeId != undefined;
    setIgnoreBetTypeStep(_ignoreBetTypeStep);
  }, [currentStep, currentGame, currentSessions, betTypeList])

  useEffect(() => {
    setLoading(isFetchingBetTypes || isFetchingSessions);
  }, [isFetchingBetTypes, isFetchingSessions])

  useEffect(() => {
    setHasAvailableSessions(currentSessions.length > 0)
  }, [currentSessions])

  useEffect(() => {
    if (!isLoading && !hasAvailableSessions) {
      onNoSession();
    }
  }, [isLoading, hasAvailableSessions])

  //====================================== Dispatch

  const setCurrentStep = step => {
    dispatch(betBuilderActions.set_current_step(step));
  }

  const setFirstStep = step => {
    dispatch(betBuilderActions.set_first_step(step));
  }
  //====================================== Navigation

  const goBasket = () => {
    navigate(routePath.basket);
  }

  //====================================== Functions

  const loadGameData = () => {
    if (!currentGame) return;
    setLoading(true);
    console.log(`Starting new bet creation > request info for game ${currentGame.gameId}`);
    const isBetTypesLoaded = betTypeList.length > 0;
    dispatch(gamesActions.getSessionsFuture(currentGame.gameType, currentGame.gameId));
    if (isBetTypesLoaded == false) {
      dispatch(gamesActions.getBetTypeList(currentGame.gameType, currentGame.gameId));
    }
  }

  const initializeSteps = () => {
    //-- Manage session & betType selection mode
    /*
      case 1 : INSTANT LIVE (ex: FOGO) : don't choose sessions, only a betType, and later, the consecutive draw count
      case 2 : INSTANT DELAYED (ex: 5+2/90) : choose both sessions (multiples) & betType
      case 3 : MUTUAL CLASSIC (ex: 6/45) : choose a unique session, no betType selection (each session has its own betType)
      case 4 : MUTUAL HUB (ex: Africa Millions) : choose a unique session & betType
    */

    console.log(`Game ${currentGame?.gameId}: found ${betTypeList.length} betTypes / ${currentSessions?.length} sessions`);

    if (!currentGame || !currentSessions?.length || betTypeList.length == 0) {
      // still don't have fetched all the init data, or no session available
      return false;
    }

    console.log(`Initialize steps...`);

    // ignore session selection step if: we need to select the consecutive draw count in a future step
    const _ignoreSessionStep = betTypeList[0].maxDrawByBet > 1;
    setIgnoreSessionStep(_ignoreSessionStep);

    // ignore the betType selection step if: only one active betType OR if the sessions are already attached to a specific betType
    const sessionBetTypeId = currentSessions?.[0]?.betTypeId;
    const _ignoreBetTypeStep = betTypeList.length == 1 || sessionBetTypeId != undefined;
    setIgnoreBetTypeStep(_ignoreBetTypeStep);

    if (_ignoreBetTypeStep) {
      // pre-select the betType here, as we don't go on the BetType page
      const usedBetType = sessionBetTypeId && betTypeList.find(E => E.betTypeId == sessionBetTypeId) || betTypeList[0];
      dispatch(betBuilderActions.set_betType_selected(usedBetType));
    }

    const _firstStep = _ignoreSessionStep == false ? BetBuilderSteps.SESSION :
      _ignoreBetTypeStep == false ? BetBuilderSteps.BETTYPE
        : BetBuilderSteps.GRID;
    setFirstStep(_firstStep);
    setCurrentStep(_firstStep);

    console.log(`Bet settings: ignoreSession=${_ignoreSessionStep}, ignoreBetType=${_ignoreBetTypeStep}`);
    return true;
  }

  const goNextStep = () => {
    scrollMainContentToTop();
    switch (currentStep) {
      case BetBuilderSteps.NONE: {
        break;
      }
      case BetBuilderSteps.INIT: {
        setCurrentStep(firstStep);
        break;
      }
      case BetBuilderSteps.SESSION: {
        setCurrentStep(ignoreBetTypeStep ? BetBuilderSteps.GRID : BetBuilderSteps.BETTYPE);
        break;
      }
      case BetBuilderSteps.BETTYPE: {
        setCurrentStep(BetBuilderSteps.GRID);
        break;
      }
      case BetBuilderSteps.GRID: {
        setCurrentStep(BetBuilderSteps.STAKE);
        break;
      }
      case BetBuilderSteps.STAKE: {
        selectedBetType.maxDrawByBet > 1 ? setCurrentStep(BetBuilderSteps.CONSECUTIVEDRAWS) : validateBet();
        break;
      }
      case BetBuilderSteps.CONSECUTIVEDRAWS: {
        validateBet();
        break;
      }
    }
  }

  const goPreviousStep = () => {
    scrollMainContentToTop();
    switch (currentStep) {
      case BetBuilderSteps.NONE:
      case BetBuilderSteps.INIT:
      case BetBuilderSteps.SESSION: {
        break;
      }
      case BetBuilderSteps.BETTYPE: {
        setCurrentStep(BetBuilderSteps.SESSION);
        break;
      }
      case BetBuilderSteps.GRID: {
        setCurrentStep(ignoreBetTypeStep ? BetBuilderSteps.SESSION : BetBuilderSteps.BETTYPE);
        break;
      }
      case BetBuilderSteps.STAKE: {
        setCurrentStep(BetBuilderSteps.GRID);
        break;
      }
      case BetBuilderSteps.CONSECUTIVEDRAWS: {
        setCurrentStep(BetBuilderSteps.STAKE);
        break;
      }
    }
  }

  const onNoSession = () => {
    navigate(routePath.error + '?errorType=' + EErrorType.no_session);
  }

  const validateBet = () => {
    // convert to basket items
    let basketItemsToAdd = dispatch(betBuilderActions.toBasketItemList());
    dispatch(basketActions.set_game_info(currentGame))
    if (isEditionMode && betBuilder.betIdToEdit) {
      const itemToRemove = basketItemList.find(item => item.betId == betBuilder.betIdToEdit) as IBasketItem;
      dispatch(basketActions.removeItem(itemToRemove));

    }
    dispatch(basketActions.addItemList(basketItemsToAdd));
    // open the basket
    goBasket();
    // reset the betCreator data
    dispatch(betBuilderActions.reset_data());
  }


  //====================================== Render

  const renderLoading = () => (
    <Box sx={style.pageLoading}>
      <CircularProgress size={'30px'} />
    </Box>
  )

  const renderStep = (): JSX.Element => {
    const _props = { goNextStep, goPreviousStep };
    switch (currentStep) {
      case BetBuilderSteps.SESSION: return <SessionPicker {..._props} />;
      case BetBuilderSteps.BETTYPE: return <BetTypePicker {..._props} />;
      case BetBuilderSteps.GRID: return <GridPicker {..._props} />;
      case BetBuilderSteps.STAKE: return <StakePicker {..._props} />;
      case BetBuilderSteps.CONSECUTIVEDRAWS: return <ConsecutiveDrawsPicker {..._props} />;
      default: return <></>;
    }
  }

  return (    
     <Box id="bet-builder" sx={style.container}>

      {currentStep != firstStep &&
        <Box sx={style.internalNavBar}>
          <BackButton onClick={goPreviousStep} />
        </Box>
      }

      {isLoading && renderLoading()}

      {!isLoading && hasAvailableSessions && renderStep()}

    </Box>
  )
}

export default BetBuilderPage;