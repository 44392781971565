export const translationEN = {
  "back": "Back",
  "balance": "Balance",
  "cancel": "Cancel",
  "clear": "Clear",
  "combinations": "Combinations",
  "confirm": "Confirm",
  "draw": "Draw",
  "draws": "Draws",
  "gain": "Gain",
  "gameMode": "Game mode",
  "number": "Number",
  "numbers": "Numbers",
  "others": "Others",
  "refunded": "Refunded",
  "rules": "Rules",
  "stake": "Stake",
  "stakes": "Stakes",
  "tax": "Tax",
  "tickets": "Tickets",
  "ticket": "Ticket",
  "understood": "I've got it",
  "wallet": "Wallet",

  "Basket": {
    "basket": "Cart",
    "add_bet": "Add a bet",
    "insufficient_credits": "Wallet error",
    "insufficient_credits_desc": "You don't have enough money in your wallet. Please recharge it before continuing.",
    "remove_item_confirm_desc": "You are about to delete a bet. Do you want to continue?",
    "total": "Cart total",
    "total_of": "Total of the",
    "validate": "Confirm my cart",
    "empty_basket": "Empty cart,\nhow about betting?",
  },

  "Booking": {
    "bet_creation1": "Creating",
    "bet_creation2": " your bet",
  },

  "Bet": {
    "active_permutation": "Active permutation",
    "details": "Bet details",
    "max_gain_gross": "Max gross gain",
    "max_gain_net": "Max net gain",
    "max_gain": "Max gain",
  },

  "Exit": {
    "leaveTheGame": "Quitter le jeu en cours ?",
    "leave": "Quitter le jeu",
  },

  "Error": {
    "back_to_draws": "Back to drawings",
    "draw_bet_closed": "This drawing can no longer be played, please delete it",
    "not_enough_draws": "The number of draws chosen exceeds the number of draws available",
    "pageReload": "Reload the page",
    "reloadGame": "please reopen the game",
    "seeMyBets": "See my bets",
    "session_list_empty_1": "No draw",
    "session_list_empty_2": "available at the moment",
    "session_expired_1": "Your draw",
    "session_expired_2": "is not available for selection",
    "technicalError": "Technical error",
    "contact_support": "please contact the support",
    "booking_error_1": "An error ",
    "booking_error_2": "occurred while creating the ticket",
    "booking_error_action": "Return to cart",
    "booking_error_refunded_action": "Refund done",
  },

  "GameRules": {
    "game_rules": "Betting rules",
    "max_gain": "Max gain",
    "rules": "Rules",
    "what_is_it": "What is it ?",
    "enter_an_amount": "Enter an amount",
    "potential_win_default": "Potential winnings for the default bet."
  },

  "Grid": {
    "permutations": "Permutations",
    "max_selections_reached": "You cannot select more than {{max}} numbers.",
    "permutate": "Permutate",
    "validate": "Confirm",
    "permutation_enabled": "Active permutation",
    "permutation_entering": "You are about to enter permutation",
    "permutation_help_1": "Playing <[Permutation]> means choosing more numbers than the amount of numbers defined for the bet type (Simple).",
    "permutation_help_2": "If a player chooses <[X]> numbers in permutation, then he's playing the equivalent of <[N]> simple games corresponding to <[N]> simple combinations.",
    "permutation_mincount_1": "Activate permutation from",
    "permutation_mincount_2": "numbers",
    "select_your_numbers_1": "Select your ",
    "select_your_numbers_2": "numbers",
    "validate_selection": "Validate my selection",
    "delete_grid": "Clear grid"
  },

  "MyBets": {
    "pending": "Current",
    "past": "Past",
    "filter": "Filter",
  },

  "Session": {
    "welcome": "Welcome on ",
    "select_game_session": "Choose a game session",
    "select_game_sessions": "Choose game sessions",
    "validate_selection": "Validate my selection",
    "today": "Today",
    "tomorrow": "Tomorrow"
  },

  "Stake": {
    "error_amount_too_high": "Amount too high, you cannot enter more than {{N}} {{currency}}.",
    "error_amount_too_low": "Amount too low, you cannot enter less than {{N}} {{currency}}.",
    "error_amount_staggering": "Only intervals of {{N}} are valid, please re-enter a correct amount.",
    "error_total_price_exceeded": "The total price of the cart is too high (max. {{N}}).",
    "title1": "Bet ",
    "title2": "amount",
    "personalized_amount": "Personalized amount",
    "unit_price": "Unit price",
    "unit_ticket_price": "Unit ticket price",
    "reset_grid": "You are about to erase your grid, are you sure?",
    "ticket_price": "Ticket price",
    "max_price": "Max price",
    "validate_amount": "Validate the amount",
    "validate_stake": "Validate the stake",
    "max_draws": "Maximum draws"
  },

  "Ticket": {
    "draw_results": "Draw results",
    "wait": "Upcoming draws",
    "won": "Won",
    "canceled": "Canceled",
    "lost": "Lost",
    "totalCost": "Total cost",
    "refTicket": "Ticket ref",
    "contact_support": "Contact support",
    "win_payment_blocked_error": "We encountered a technical error paying your winnings",
    "canceled_payment_blocked_error": "We encountered a technical error while paying your refunds",
    "empty_tickets": "Don't have a bet? Let's make one",
    "email": "Mail",
    "n_transaction": "Transaction n°",
    "bet": "Bet",
    "show_more_results": "Show more results"
  }
}