import { ACTIONS } from '../actions/booking.actions';
import { IBookingResponse, IBookingState } from 'src/models/ticketing/booking.model';
export interface IBookingReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  bookingState: IBookingState;
  bookingData: IBookingResponse;
}

const initialState = <IBookingReducer>{
  error: false,
  isFetching: true,
  errorMessage: '',
  bookingState: {},
  bookingData: {},
}


export default function bookingReducer(state = initialState, action): IBookingReducer {
  switch (action.type) {
    //------------------
    // STATES
    case ACTIONS.BOOKING_ERROR: {
      return {
        ...state,
        error: true,
        isFetching: false,
        errorMessage: action.payload?.message,
      }
    }
    case ACTIONS.BOOKING_FETCHING: {
      return {
        ...state,
        isFetching: true,
        error: false,
      }
    }
    case ACTIONS.BOOKING_FETCHING_DONE: {
      return {
        ...state,
        isFetching: false,
        error: false,
        bookingData: action.payload
      }
    }
    case ACTIONS.UPDATE_CURRENT_BOOKING: {
      return {
        ...state,
        isFetching: false,
        bookingState: action.payload,
      }
    }
    case ACTIONS.CLEAR_STATUS: {
      return {
        ...state,
        isFetching: false,
        bookingState: {} as IBookingState,
      }
    }
    default:
      return state
  }
}