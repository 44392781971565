import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Analytics from "src/store/services/analytics.service";

const usePageTracking = () => {
  
  const location = useLocation();

  useEffect(() => {
    Analytics.trackPage(location.pathname);
  }, [location]);
};

export default usePageTracking;
