const SVGReqPayment = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
      <rect width="169.858" height="173.062" rx="34.0886" transform="matrix(0.985058 0.172222 -0.163038 0.98662 28.2156 0)" fill="#BCA8F0"/>
      <rect width="166.228" height="168.071" rx="34.0886" transform="matrix(0.999891 0.0147752 -0.0139653 0.999902 14.8076 13.623)" fill="url(#paint0_linear_994_31190)"/>
      <mask id="mask0_994_31190" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="12" y="14" width="169" height="170">
        <rect width="166.228" height="168.071" rx="34.0886" transform="matrix(0.999891 0.0147752 -0.0139653 0.999902 14.7388 13.623)" fill="url(#paint1_linear_994_31190)"/>
      </mask>
      <g mask="url(#mask0_994_31190)">
        <path d="M53.5465 180.645L28.9495 113.71L118.044 25.8065L190.737 66.1291L198.621 142.742L199.41 178.226L81.1423 202.419L53.5465 180.645Z" fill="url(#paint2_linear_994_31190)"/>
      </g>
      <path d="M34.8644 87.173C33.6028 87.8181 29.0824 86.9042 26.9799 86.3665L26.1914 82.3343L28.5568 78.302L37.2297 83.9472C36.9669 84.7536 36.1259 86.5278 34.8644 87.173Z" fill="#D39703"/>
      <path d="M4.90303 80.7214C3.64151 79.4311 9.65031 74.9999 11.7528 73.387L20.6721 78.3021C22.7746 80.4526 26.9797 85.2375 26.9797 87.173C26.9797 89.5924 23.0374 92.8182 21.4605 93.6246C19.8836 94.4311 16.7298 85.5601 15.1529 83.9472C13.576 82.3343 6.47993 82.3343 4.90303 80.7214Z" fill="#D39703"/>
      <path d="M7.26855 67.8181C7.26855 66.5278 10.6852 63.9773 11.9993 63.1709L18.3068 71.8503L11.9993 74.2697C10.4224 72.6568 7.26855 69.1084 7.26855 67.8181Z" fill="#D39703"/>
      <path d="M9.63355 76.6894C8.37203 76.0442 12.2617 72.1195 14.3642 70.2377C14.1014 69.7001 13.2605 68.141 11.9989 66.2055C10.7374 64.27 11.4733 63.2485 11.9989 62.9797C14.522 62.3345 19.3578 65.9367 21.4603 67.8184C23.5629 67.2808 28.241 66.3668 30.1333 67.0119C32.4986 67.8184 30.1333 75.0765 30.1333 75.8829C37.2293 82.3345 38.0178 83.9474 37.2293 83.9474C36.5986 83.9474 31.1846 82.8722 28.5564 82.3345C28.2936 84.2162 27.2949 88.141 25.4026 88.7861C23.5103 89.4313 19.8834 83.141 18.3065 79.9152C15.9412 79.1087 10.8951 77.3345 9.63355 76.6894Z" fill="#FDD470"/>
      <path d="M121.981 164.419C126.459 157.576 118.805 150.264 114.418 147.464C113.981 145.947 115.205 146.311 115.872 146.683C119.565 148.318 122.864 146.868 124.051 145.938C132.813 151.886 135.505 162.409 132.56 165.012C130.205 167.095 124.526 165.484 121.981 164.419Z" fill="#FDD470"/>
      <path d="M61.5423 18.2907C54.1831 28.3148 65.2574 40.0901 71.7145 44.7248C72.2588 47.0694 70.4243 46.4108 69.439 45.7884C63.9451 42.9849 58.8088 44.9092 56.9274 46.2218C44.0591 36.4257 40.795 20.1912 45.4837 16.4835C49.2346 13.5173 57.7523 16.4524 61.5423 18.2907Z" fill="#FDD470"/>
      <g filter="url(#filter0_d_994_31190)">
        <path d="M111.742 25.8496L27.2119 111.319C26.0358 113.118 27.7019 115.567 28.682 116.567L44.853 133.061C46.078 133.811 49.1162 134.711 51.4684 132.311C54.4085 129.312 58.8188 127.813 63.9641 133.061C69.1094 138.309 66.9043 142.808 63.9641 145.807C61.612 148.206 62.9841 151.804 63.9641 153.304L77.93 167.549C81.4582 171.147 85.7704 168.548 87.4855 166.799L169.076 83.5789C172.016 80.58 169.811 77.3311 168.34 76.0816L153.64 61.087C151.287 58.6878 148.739 60.3101 147.759 61.4212C145.554 63.6704 139.821 66.8192 134.528 61.4212C127.913 54.6736 132.323 49.841 134.528 46.8421C136.293 44.4429 134.283 41.344 133.058 40.0945C129.873 36.5957 122.621 28.8485 119.092 25.8496C115.564 22.8507 112.722 24.6 111.742 25.8496Z" fill="url(#paint3_linear_994_31190)"/>
      </g>
      <path d="M110.434 33.0316L34.3565 109.846C33.298 111.463 34.7975 113.664 35.6796 114.563L46.4115 125.531C47.5141 126.205 50.2485 127.014 52.3654 124.858C55.0116 122.162 62.8027 124.67 67.4335 129.386C72.0643 134.103 73.3845 141.479 70.7384 144.174C68.6214 146.331 69.8563 149.565 70.7384 150.913C73.605 153.832 76.8274 157.147 80.0028 160.382C83.1782 163.616 87.0592 161.28 88.6028 159.708L162.034 84.9149C164.68 82.2197 162.695 79.2999 161.372 78.1768C157.844 74.5832 153.696 69.28 151.579 67.1238C149.462 64.9676 149.375 66.3826 147.022 68.1655C145.037 70.1869 135.704 69.8525 130.941 65.001C124.987 58.9367 126.414 52.0289 128.399 49.3336C129.986 47.1775 130.721 46.957 129.618 45.834C126.752 42.6896 120.224 35.7269 117.049 33.0316C113.874 30.3364 111.316 31.9086 110.434 33.0316Z" stroke="#5C2CD9"/>
      <rect width="8.85719" height="2.94887" rx="1.47444" transform="matrix(0.679925 0.733282 -0.71777 0.69628 103.734 47.4733)" fill="#5C2CD9"/>
      <rect width="8.85719" height="2.94887" rx="1.47444" transform="matrix(0.679925 0.733282 -0.71777 0.69628 113.945 57.9164)" fill="#5C2CD9"/>
      <rect width="8.85719" height="2.94887" rx="1.47444" transform="matrix(0.679925 0.733282 -0.71777 0.69628 122.697 66.8682)" fill="#5C2CD9"/>
      <rect width="8.85719" height="2.94887" rx="1.47444" transform="matrix(0.679925 0.733282 -0.71777 0.69628 132.907 77.3108)" fill="#5C2CD9"/>
      <rect width="8.85719" height="2.94887" rx="1.47444" transform="matrix(0.679925 0.733282 -0.71777 0.69628 141.658 86.2621)" fill="#5C2CD9"/>
      <path d="M194.879 109.587C194.879 111.361 192.711 114.022 191.627 115.13L179.705 110.696L190.543 99.4954C191.988 102.452 194.879 107.814 194.879 109.587Z" fill="#D39703"/>
      <path d="M169.952 148.387C168.217 148.387 165.616 143.214 164.532 140.627L176.454 123.999L195.963 118.456C202.032 125.551 199.937 128.064 198.131 128.433C194.157 129.911 185.558 133.089 182.957 133.976C179.706 135.085 172.119 148.387 169.952 148.387Z" fill="#D39703"/>
      <path d="M150.842 140.512C148.104 140.512 145.461 135.339 143.94 132.752L158.828 129.427L161.11 133.861L164.533 139.404C161.11 139.773 153.58 140.512 150.842 140.512Z" fill="#D39703"/>
      <path d="M143.94 131.759L155.861 120.673C156.223 118.086 156.945 112.248 156.945 109.588C156.945 106.927 159.835 107.001 161.28 107.37L174.286 108.479C176.454 106.631 181.873 102.271 186.208 99.6105C190.543 96.9499 190.904 99.241 190.543 100.719L187.292 112.913C188.737 113.652 192.277 115.574 194.878 117.347C197.48 119.121 195.962 121.043 194.878 121.782L178.621 126.216C175.731 130.281 169.517 138.853 167.783 140.627C166.049 142.401 164.893 141.366 164.532 140.627L160.197 133.976H147.191C142.856 133.976 143.217 132.498 143.94 131.759Z" fill="#FDD470"/>
      <path d="M41.7881 63.7098L48.0957 67.7421L46.5188 69.355L40.2112 67.7421L41.7881 63.7098Z" fill="white"/>
      <path d="M145.075 26.613L138.767 37.0969L139.556 43.5485L149.806 31.4517L145.075 26.613Z" fill="white"/>
      <path d="M20.5 123.387L28.3845 121.774L30.7498 124.194L22.8653 128.226L20.5 123.387Z" fill="white"/>
      <defs>
        <filter id="filter0_d_994_31190" x="14.8074" y="18.1937" width="167.717" height="169.161" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="6"/>
          <feGaussianBlur stdDeviation="6"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_994_31190"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_994_31190" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_994_31190" x1="1.92484" y1="44.0938" x2="167.825" y2="109.023" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C621E5"/>
          <stop offset="1" stopColor="#7D7CF9"/>
        </linearGradient>
        <linearGradient id="paint1_linear_994_31190" x1="1.92484" y1="44.0938" x2="167.825" y2="109.023" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5827D8"/>
          <stop offset="1" stopColor="#9B7DE8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_994_31190" x1="66.9353" y1="55.6452" x2="190.118" y2="170.969" gradientUnits="userSpaceOnUse">
          <stop stopOpacity="0.6"/>
          <stop offset="0.732769" stopOpacity="0.15"/>
        </linearGradient>
        <linearGradient id="paint3_linear_994_31190" x1="84.7129" y1="56.2423" x2="138.2" y2="107.347" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="#DACFF6"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
export default SVGReqPayment;