import { TDrawsAsObject } from 'src/models/lottery/draw.model';
import { ACTIONS } from '../actions/ticket.actions';
import { IBetTicket } from 'src/models/ticketing/ticket.model';
import { IPageable } from 'src/models/core/pagination.model';

export interface ITicketReducer {
  error: boolean;
  isFetching: boolean;
  errorMessage: string;
  pendingBetList: IBetTicket[];
  drawResults: TDrawsAsObject;
  isDrawResultsFetching: boolean;
  activeBetPList: IPageable<IBetTicket>;
  pastBetPList: IPageable<IBetTicket>;
}

const initialState = <ITicketReducer>{
  error: false,
  isFetching: true,
  isDrawResultsFetching: false,
  errorMessage: '',
  drawResults: {},
  activeBetPList: {},
  pastBetPList: {},
}


export default function ticketsReducer(state = initialState, action): ITicketReducer {
  switch (action.type) {
    //------------------
    // TICKETS
    case ACTIONS.TICKETS_ERROR: {
      return {
        ...state,
        error: true,
        isFetching: false,
        errorMessage: action.payload?.message,
      }
    }
    case ACTIONS.TICKETS_FETCHING: {
      return { ...state, isFetching: true }
    }
    case ACTIONS.TICKETS_ACTIVE_PLAYER_BETS: {
      const betPList: IPageable<IBetTicket> = action.payload;
      return {
        ...state,
        isFetching: false,
        activeBetPList: betPList,
      }
    }
    case ACTIONS.TICKETS_PAST_PLAYER_BETS: {
      const betPList: IPageable<IBetTicket> = action.payload;
      return {
        ...state,
        isFetching: false,
        pastBetPList: betPList,
      }
    }
    //------------------
    // DRAW RESULTS
    case ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING: {
      return {
        ...state,
        isDrawResultsFetching: true
      }
    }
    case ACTIONS.TICKETS_DRAWS_RESULTS_FETCHING_DONE: {
      return {
        ...state,
        isDrawResultsFetching: false,
      }
    }
    case ACTIONS.TICKETS_DRAW_RESULTS: {
      return {
        ...state,
        isDrawResultsFetching: false,
        drawResults: action.payload,
      }
    }
    default:
      return state
  }
}