import { Theme } from "@mui/material";
import commonStyle from "src/common/style/commonStyle";

export const makeStyle = (theme: Theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0, //scroll
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '48px',
      paddingRight: '48px'
    },
  },
  body: {
    minHeight: 0, //scroll
    height: '100%',
  },
  header: {
    paddingLeft: '11px',
    paddingRight: '11px',
  },
  headerBox: {
    background: theme.palette.sticker.main,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    height: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginBottom: '24px',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '52px',
    },
  },
  headerText: {
    color: '#FFF',
    fontSize: '18px',
    fontWeight: 700,
  },
  gridsSummaryContainer: {
    [theme.breakpoints.up('lg')]: {
      minHeight: 0, //scroll
      height: '100%',
      maxWidth: '600px',
      margin: 'auto',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  stakeBtnBox: {
    padding: '4px',
  },
  stakeBtn: {
    display: 'flex',
    height: '48px',
    padding: '12px 24px',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexDirection: 'row',
    width: '100%',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
    '&:hover': { backgroundColor: '#FFF' },
    '&:disabled': { backgroundColor: '#FFF' },
  },
  stakeBtnSelected: {
    background: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: theme.palette.primary.main },
  },
  stakeBtnText: {
    fontSize: 16,
    color: '#272727',
    fontWeight: 700
  },
  stakeBtnTextSelected: {
    color: '#FFF',
  },
  stakeBtnTextCurrency: {
    fontSize: 10,
    marginLeft: '3px',
  },
  stakeContainer: {
    [theme.breakpoints.up('lg')]: {
      ...commonStyle.innerScrollHeight(theme),
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  stakePickerBox: {
    margin: '16px 11px',
    marginBottom: '20px',
  },
  stakePickerTitleText: {
    fontWeight: 700,
    fontSize: 20,
    color: '#272727'
  },
  stakePickerTitleImportantText: {
    fontWeight: 700,
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  uniqueStakeTxt: {
    fontSize: 16,
    color: '#272727',
    fontWeight: 800,
  },
  ticketPriceRecap: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginLeft: '11px',
    marginRight: '11px',
    marginBottom: '16px'
  },
  ticketPriceRecapTxt1: {
    fontSize: 16,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.40)'
  },
  ticketPriceRecapTxt2: {
    fontSize: 24,
    fontWeight: 700,
    color: '#000'
  },
  maxGainBox: {
    textAlign: 'center',
    marginLeft: '11px',
    marginRight: '11px',
    marginBottom: '30px',
    borderRadius: '32px',
    background: '#32CD32',
    boxShadow: '0px 6px 12px 0px rgba(0, 0, 0, 0.08)',
    textTransform: 'initial',
    padding: '12px'
  },
  maxGainTxt: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 700
  },
  //Validate Button
  validateContainer: {
    backgroundColor: theme.palette.background.default,
    textAlign: 'center',
    marginBottom: '11px',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row-reverse'
    },
  },
  buttonRow: {
    marginLeft: '11px',
    marginRight: '11px',
    backgroundColor: 'transparent',
    '&:first-of-type': { marginBottom: '8px' },
    maxWidth: 'none'
  },
  actionButton: {
    height: '48px',
    width: '100%',
    maxWidth: '500px',
    borderRadius: '16px',
  },
  actionButtonText: {
    color: '#FFF',
    fontWeight: 700,
    textTransform: 'initial',
    fontSize: '16px',
    marginLeft: '8px',
  },
  validateButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.main },
    '&:disabled': { backgroundColor: theme.palette.primary.light },
  },
  deleteButton: {
    backgroundColor: '#1D1D1D',
    '&:hover': { backgroundColor: '#1D1D1D' },
    '&:disabled': { backgroundColor: '#1D1D1D' },
  },

  customAmountBtn: {
    backgroundColor: '#1D1D1D',
    '&:hover': { backgroundColor: '#1D1D1D' },
    '&:disabled': { backgroundColor: '#1D1D1D' },
    height: '48px',
    width: '100%',
    marginTop: '8px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',    
    "& fieldset": {
      border: 'none',
    },
    '& input': {
      color: '#FFF',
      fontWeight: 700,
      textTransform: 'initial',
      fontSize: '16px',
      marginLeft: '8px',
      textAlign: 'center',
      MozAppearance: 'textField',
      WebkitAppearance: 'textField',
      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'textField',
      },
      "&::placeholder": {
        color: '#FFF',
        fontWeight: 700,
        fontSize: '16px',
        opacity: 1,
      },
      "&:focus::placeholder": {
        fontWeight: 400,
        opacity: 0.5,
      },
    }
  },
  customAmountBtnInvalid: {
    backgroundColor: theme.palette.error.main,
    '&:hover, &:active': { backgroundColor: theme.palette.error.main },
  },
  stakeErrorText: {
    marginTop: "12px",
    color: "#F52F2F",
    fontSize: 16,
    fontWeight: 500
  }
})