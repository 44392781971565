import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { routePath } from "src/routes";
import { EErrorType } from "src/models/core/error.model";
import GenericError, { GenericErrorProps } from "src/component/core/genericError/genericError.comp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { exitApp } from "src/store/actions/app.actions";
import { IErrorExtraData } from "src/models/core/error.model";

/**
 * Centralized errors in one page
 */
const ErrorPage = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<any>();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  //====================================== States

  const exitUrl = useSelector((state: RootState) => state.app.entryParams.exitUrl);

  const [errorProps, setErrorProps] = useState({} as GenericErrorProps);
  const [errorData, setErrorData] = useState<IErrorExtraData>();

  //====================================== Effects

  useEffect(() => {
    const errorType = parseInt(searchParams.get('errorType') || '') || EErrorType.technical;
    const _errorProps = computeErrorProps(errorType);
    setErrorProps(_errorProps);
  }, [searchParams, exitUrl])

  useEffect(() => {
    if (location?.state) {
      setErrorData(location.state);
    }
  }, [location])


  //====================================== Functions

  const getDefaultErrorProps = (): GenericErrorProps => {
    return {
      actionText: t('Error.pageReload'),
      onAction: () => navigate(routePath.myBets),
      titleStrongPrefix: t('Error.technicalError') + ',',
      title: t('Error.reloadGame'),
    }
  }

  const computeErrorProps = (errorType: EErrorType): GenericErrorProps => {
    switch (errorType) {
      //-- Sessions
      case EErrorType.no_session:
        return {
          actionText: t(exitUrl ? 'Exit.leave' : 'Error.seeMyBets'),
          onAction: () => exitUrl ? dispatch(exitApp()) : navigate(routePath.myBets),
          titleStrongPrefix: t('Error.session_list_empty_1'),
          title: t('Error.session_list_empty_2'),
        }
      case EErrorType.session_expired:
        return {
          actionText: t('Error.back_to_draws'),
          onAction: () => navigate(routePath.betBuilder),
          titleStrongPrefix: t('Error.session_expired_1'),
          title: t('Error.session_expired_2'),
        }
      //-- Booking
      case EErrorType.booking_failed:
        return {
          actionText: t('Error.booking_error_action'),
          onAction: () => navigate(routePath.basket),
          titleStrongPrefix: t('Error.booking_error_1'),
          title: t('Error.booking_error_2'),
        }
      case EErrorType.booking_failed_refund_failed:
        return {
          actionText: t('Ticket.contact_support'),
          titleStrongPrefix: t('Error.technicalError'),
          title: t('Error.contact_support'),
          data: errorData,
        }
      case EErrorType.booking_failed_refund_success:
        return {
          actionText: t('Error.booking_error_action'),
          onAction: () => navigate(routePath.basket),
          titleStrongPrefix: t('Error.booking_error_1'),
          title: t('Error.booking_error_2'),
          actionMade: t('Error.booking_error_refunded_action'),
        }
      //-- Default
      case EErrorType.technical:
      default:
        return getDefaultErrorProps();
    }
  }


  //====================================== Render
  return (
    <GenericError
      actionText={errorProps.actionText}
      onAction={errorProps.onAction}
      title={errorProps.title}
      titleStrongPrefix={errorProps.titleStrongPrefix}
      actionMade={errorProps.actionMade}
      data={errorData}
    >
      {errorProps.children}
    </GenericError>
  )
}

export default ErrorPage;