import { useState, useMemo, useEffect } from 'react';
import { createTheme, darken, lighten, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { RootState } from 'src/store/root.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { ColorAlphaCoefficient, ThemeDefaults, defaultTheme } from './theme';
import { loadAppConfig } from 'src/store/actions/app.actions';

const CustomThemeProvider = ({
  children
}) => {
  
  const dispatch = useDispatch<any>()

  const [primaryColor, setPrimaryColor] = useState(ThemeDefaults.PrimaryColor);
  const [favoriteColor, setFavoriteColor] = useState(ThemeDefaults.FavoriteColor);
  const [stickerColor, setStickerColor] = useState(ThemeDefaults.StickerColor);
  const [primaryShades, setPrimaryShades] = useState<object>(ThemeDefaults.primaryShades);

  const gameId: number = useSelector((root: RootState) => root.app.entryParams.gameId);
  const gamesSettings = useSelector((state: RootState) => state.app.config.games);

  const theme = useMemo(
    () =>
      createTheme(deepmerge(defaultTheme, {
        palette: {
          background: { default: '#F9F9F9' },
          primary: {
            main: primaryColor,
            light: lighten(primaryColor, ColorAlphaCoefficient),
            dark: darken(primaryColor, ColorAlphaCoefficient),
          },
          sticker: {
            main: stickerColor,
          },
          favorite: {
            main: favoriteColor,
            light: lighten(favoriteColor, ColorAlphaCoefficient),
            dark: darken(favoriteColor, ColorAlphaCoefficient),
          },
        },
      })),
    [primaryColor, stickerColor, primaryShades],
  );

  useEffect(() => {
    dispatch(loadAppConfig())
  }, [])

  useEffect(() => {
    if (!gameId) return;
    const gameConfig = gamesSettings?.[gameId];
    if (gameConfig) {
      gameConfig.primaryColor && setPrimaryColor(gameConfig.primaryColor);
      gameConfig.sticker && setStickerColor(gameConfig.sticker);
      gameConfig.primaryShades && setPrimaryShades(gameConfig.primaryShades);
    }
  }, [gameId, gamesSettings])

  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}

export default CustomThemeProvider