import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { Box, Typography, useTheme, TextField, Grid } from "@mui/material";
import { makeStyle } from "./Paytable.style";
import { useEffect, useState } from "react";
import { IBetType } from "src/models/lottery/betType.models";
import { IPayLine } from "src/models/lottery/payLine.model";
import { sortPaylines } from "src/store/services/gameRules.service";
import * as GamerulesActions from 'src/store/actions/gameRules.actions';

type Props = {
  currentBetType: IBetType,
}

const Paytable = ({
  currentBetType
}: Props): JSX.Element => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch();

  //====================================== States

  const gridName = useSelector((state: RootState) => state.game.mainGridName);
  const playerInfo = useSelector((state: RootState) => state.player.playerInfo);
  const customStakeAmount = useSelector((state: RootState) => state.gameRules.customStakeAmount);
  const [sortedPaylines, setSortedPaylines] = useState<IPayLine[]>();
  const [hasUniqueStake, setHasUniqueStake] = useState<boolean>(false);

  //====================================== Effects

  useEffect(() => {
    if (currentBetType) {
      setHasUniqueStake(currentBetType?.defaultPrices?.length == 1);
      if (currentBetType?.defaultPrices?.length == 1) {
        dispatch(GamerulesActions.set_custom_stake_amount(currentBetType.defaultPrices[0]));
      }
      if (currentBetType?.listOfPayLines) {
        setSortedPaylines(sortPaylines(currentBetType?.listOfPayLines, gridName));        
      }
    }
  }, [currentBetType])


  //====================================== Functions

  const onCustomAmountChange = (sValue: string) => {
    const amount: number = parseInt(sValue) || 0;
    dispatch(GamerulesActions.set_custom_stake_amount(amount));
  }

  const computeBGColor = (index: number, gainValue: number) => {
    if (gainValue === 0) return '#FFF';
    switch (index) {
      case 0:
        return 'linear-gradient(90deg, #FFD970 0%, #F5B400 100%)'
      case 1:
        return 'linear-gradient(90deg, #E2E3DB 0%, #BEBFB9 100%)'
      case 2:
        return 'linear-gradient(90deg, #FFB478 0%, #DB9257 100%)'
      default:
        return '#FFF'
    }
  }

  const computeCircleBorderColor = (index: number, gainValue: number) => {
    if (gainValue === 0) return '#FFF';
    switch (index) {
      case 0:
        return '#FFD970'
      case 1:
        return '#E2E3DB'
      case 2:
        return '#FFB478'
      default:
        return '#FFF'
    }
  }

  const computeUnderTop3Color = (index: number, gainValue: number) => {
    return index > 2 || gainValue === 0 ? '#1D1D1D' : '#FFF';
  }

  const renderCustomStakePicker = () => (
    <TextField
      type="text"
      inputMode="numeric"
      sx={style.customAmountInput}
      variant="outlined"
      placeholder={t('GameRules.enter_an_amount')}
      onChange={e => onCustomAmountChange(e.target.value)}
      value={customStakeAmount || ''}
      autoComplete="off"
      inputProps={{
        autoComplete: "off",
        max: currentBetType?.maxBetAmount,
        min: currentBetType?.minBetAmount,
      }}
      InputProps={{
        autoComplete: "off",
        endAdornment: <Typography>{playerInfo.currencyId}</Typography>
      }}
    />
  )

  const renderUniqueStake = () => (
    <Typography sx={style.uniqueStakeText}>
      {t("GameRules.potential_win_default")}
    </Typography>
  )

  //====================================== Render

  return (
    <Box sx={style.container}>
      <Grid container sx={style.subContainer}>
        <Grid item xs={12} lg={6} sx={style.blockLeft}>
          {hasUniqueStake ? renderUniqueStake() : renderCustomStakePicker()}
        </Grid>

        <Grid item xs={12} lg={6}
          sx={style.blockRight}
        >
          {sortedPaylines?.map((pL, index) => (
            pL?.winConditions[gridName]?.totalFoundNumbers > 0 && 
            <Box sx={[style.payLineBox, {background: computeBGColor(index, pL.gainValue)}]} key={index}>
              <Box sx={style.boxCircle}>
                {Array.from({length: pL?.winConditions[gridName]?.totalFoundNumbers}, (_, _index) => _index + 1)
                  .map((num, idx) => (
                    <Box
                      key={idx}
                      sx={[
                        style.circle,
                        {backgroundColor: computeUnderTop3Color(index, pL.gainValue), border: `2px solid ${computeCircleBorderColor(index, pL.gainValue)}}`}
                      ]}
                    />
                ))
                }
              </Box>
              <Typography
                sx={[
                  style.gainValue,
                  {color: computeUnderTop3Color(index, pL.gainValue)}
                ]}
              >
                {(pL.gainValue * customStakeAmount).toLocaleString()} {playerInfo.currencyId}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

//======================================
export default Paytable;