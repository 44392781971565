import * as service from 'src/store/services/gameRules.service';
import { cache_expired, cache_reset_from_model } from '../services/cache.service';
import { IGameRuleSlide } from 'src/models/lottery/gameRules.model';

export const ACTIONS = {
  GAMERULES_FETCHING: 'GAMERULES_FETCHING',
  GAMERULES_FAILED: 'GAMERULES_FAILED',
  GAMERULES_DATA: 'GAMERULES_DATA',
  CURRENT_PAYLINES_RULES: 'CURRENT_PAYLINES_RULES',
  UPDATE_CUSTOM_STAKE_AMOUNT: 'UPDATE_CUSTOM_STAKE_AMOUNT',
}

export const set_game_rules = (data: IGameRuleSlide[]) => {
  return { type: ACTIONS.GAMERULES_DATA, payload: data };
}

export const set_state_fetching = () => {
  return { type: ACTIONS.GAMERULES_FETCHING };
}
export const set_state_failed = (err: any) => {
  return { type: ACTIONS.GAMERULES_FAILED, payload: err };
}
export const set_current_paylines_rules = (data) => {
  return { type: ACTIONS.CURRENT_PAYLINES_RULES, payload: data };
}
export const set_custom_stake_amount = (data: number) => {
  return { type: ACTIONS.UPDATE_CUSTOM_STAKE_AMOUNT, payload: data };
}

export const loadGameRules = () => {
  return async dispatch => {
    try {
      if (cache_expired(service.api_cache.key)) {
        dispatch(set_state_fetching());
        let r = await service.loadGameRules();
        dispatch(set_game_rules(r.slides || []))
        cache_reset_from_model(service.api_cache);
      }
    } catch (err) {
      dispatch(set_state_failed(err));
    }
  }
}
