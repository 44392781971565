import { ITaxLevel } from 'src/models/ticketing/taxes.model';
import { ACTIONS } from '../actions/taxes.actions';

export interface ITaxesReducer {
  isFetching: boolean;
  taxLevels: ITaxLevel[];
  useTaxes: boolean;
}

const initialState = <ITaxesReducer>{
  isFetching: false,
  taxLevels: [],
  useTaxes: false,
}

export default function taxesReducer(state = initialState, action): ITaxesReducer {
  switch (action.type) {
    case ACTIONS.TAXES_FETCHING: {
      return {
        ...state,
        isFetching: true,
      }
    }
    case ACTIONS.TAXES_CONFIG: {
      return {
        ...state,
        taxLevels: action.payload || [],
        isFetching: false,
        useTaxes: action.payload?.length > 0,
      }
    }
    case ACTIONS.TAXES_NONE: {
      return {
        ...state,
        isFetching: false,
        useTaxes: false,
      }
    }
    default:
      return state
  }
}