import { makeStyle } from "./exitButton.style";
import { Button, useTheme } from "@mui/material";
import { useState } from "react";
import SVGCross from "src/assets/icons/SVGCross";
import ConfirmPopup from "src/component/common/confirmPopup/confirmPopup.comp";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import { useTranslation } from "react-i18next";
import { exitApp } from "src/store/actions/app.actions";

export const ExitButton = () => {

  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const [openExitPopup, setOpenExitPopup] = useState(false);
  const showExitButton = useSelector((state: RootState) => state.app.showExitButton);
  const currentGame = useSelector((state: RootState) => state.game.game);


  //====================================== Functions


  const onOpenExitPopup = () => {
    setOpenExitPopup(true)
  }

  const onCloseExitPopup = () => {
    setOpenExitPopup(false)
  }

  const onConfirmExitPopup = () => {
    dispatch(exitApp());
  }

  //====================================== Render
  return (
    <>
      {showExitButton &&
        <Button
          onClick={onOpenExitPopup}
          sx={style.exitButton}
          disableRipple
        >
          <SVGCross />
        </Button>
      }

      <ConfirmPopup
        open={openExitPopup}
        onDismiss={onCloseExitPopup}
        title={t("Exit.leave", { game: currentGame?.name })}
        dismissButtonText={t('cancel')}
        confirmButtonText={t('Exit.leave')}
        onConfirm={onConfirmExitPopup}
        type="info"
      >
        {t('Exit.leaveTheGame')}
      </ConfirmPopup>
    </>
  )
}

export default ExitButton;