import { Theme } from "@mui/material";

export const makeStyle = (theme: Theme) => ({
  permutationContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: '12px',
    }
  },
  permutationEnabled: {
    border: `0.5px solid #D9D6FE`,
    backgroundColor:"#F4F3FF",
    display: 'inline-block',
    borderRadius: '32px',
    marginBottom: '8px',
    marginRight: '8px',
    [theme.breakpoints.up('md')]: {
      marginTop: '12px',
    }
  },
  permutationEnabledText: {
    fontWeight: 700,
    fontSize: '14px',
    color: "#5925DC",
    padding: '4px 12px',
  },
  permutationText: {
    fontWeight: 500,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: '16px',
      fontWeight: 700,
    },
  },
  permutationMinCountText: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    padding: '4px 8px',
    marginLeft: '8px',
    marginRight: '8px',
    fontWeight: 700,
    fontSize: '14px',
    borderRadius: '4px',
  },
  permutationInfoIcon: {
    padding: '4px 8px',
    display: 'inline-block',
    verticalAlign: 'sub',
  },
  permutationInfoPopupText: {
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'left',
  },
  permutationInfoPopupStrongText: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.primary.main,
  },
})