import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/root.reducer";
import * as betBuilderActions from "src/store/actions/betBuilder.actions";
import { Box, Fab, Typography, useTheme } from "@mui/material";
import { makeStyle } from "./gridContent.style";
import { IBetType } from "src/models/lottery/betType.models";
import { sortNumber, zeroFill } from "src/common/utils/format.utils";
import { IGridDefinition, IGridSelection, TGridSelectionsObj } from "src/models/lottery/grid.model";
import ConfirmPopup from "src/component/common/confirmPopup/confirmPopup.comp";
import AlertPopup from "src/component/common/alertPopup/alertPopup.comp";

type Props = {
  grid: IGridDefinition,
  gridIndex: number,
  readonly?: boolean,
}

const GridContent = ({
  grid,
  gridIndex,
  readonly,
}: Props) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const style = makeStyle(theme);
  const dispatch = useDispatch<any>();

  //====================================== States

  const [gridSelection, setGridSelection] = useState<IGridSelection>();

  const [openPopup_permutationEntering, setOpenPopup_permutationEntering] = useState(false);
  const [openPopup_maxSelection, setOpenPopup_maxSelection] = useState(false);
  const [pendingNumber_permutationEntering, setPendingNumber_permutationEntering] = useState(0);
  const [maxSelectionCount, setMaxSelectionCount] = useState(0);

  const selectedBetType: IBetType = useSelector((state: RootState) => state.betBuilder.selectedBetType);
  const selectedGrids: TGridSelectionsObj = useSelector((state: RootState) => state.betBuilder.selectedGrids);

  //====================================== Effects

  useEffect(() => {
    const _grid = selectedGrids[grid.gridName];
    setGridSelection(_grid);
    setMaxSelectionCount(_grid ? _grid.requiredNumber + _grid.maxAdditional : 0);
  }, [selectedGrids])


  //====================================== Functions

  const onNumberSelectChange = (number: number) => {
    if (gridSelection) {
      const { selectedNumbers, requiredNumber, maxAdditional } = gridSelection;
      //remove
      if (selectedNumbers.includes(number)) {
        removeNumberFromSelection(number);
      }
      //add (first additional) : ask for confirmation
      else if (selectedNumbers.length == requiredNumber && maxAdditional > 0) {
        setPendingNumber_permutationEntering(number);
        setOpenPopup_permutationEntering(true);
      }
      //add
      else if (selectedNumbers.length < requiredNumber + maxAdditional) {
        addNumberToSelection(number);
      }
      else {
        setOpenPopup_maxSelection(true);
      }
    }
  }

  const addNumberToSelection = (number: number) => {
    if (gridSelection) {
      const tempNumbers = [...gridSelection.selectedNumbers, number];
      tempNumbers.sort(sortNumber);
      dispatch(betBuilderActions.updateSelectedGrid(grid.gridName, tempNumbers));
    }
  }
  const removeNumberFromSelection = (number: number) => {
    if (gridSelection) {
      const { selectedNumbers } = gridSelection;
      let tempNumbers = selectedNumbers.filter(E => E != number);
      dispatch(betBuilderActions.updateSelectedGrid(grid.gridName, tempNumbers));
    }
  }

  const isNumberSelected = (numb: number): boolean => {
    return gridSelection != null && gridSelection.selectedNumbers.includes(numb);
  }

  const onConfirmPermutationEntering = () => {
    addNumberToSelection(pendingNumber_permutationEntering);
    setPendingNumber_permutationEntering(0);
    setOpenPopup_permutationEntering(false);
  }
  const onDismissPermutationEntering = () => {
    setOpenPopup_permutationEntering(false);
    setPendingNumber_permutationEntering(0);
  }

  const hideMaxSelectionPopup = () => {
    setOpenPopup_maxSelection(false);
  }

  //====================================== Render

  return (
    <Box id="grid-content" key={gridIndex} sx={{ marginTop: '32px' }}>

      <Box sx={[style.grid, readonly == true && style.readonly]}>

        {selectedBetType?.listOfGrids.length > 1 &&
          <Typography sx={style.gridTitle}>
            {grid.gridName}
          </Typography>
        }

        <Box sx={style.gridPicker}>
          {grid.listOfLottoNumbers.map((numb, index) => {
            const _selected: boolean = isNumberSelected(numb);
            return (
              <Fab
                key={index}
                onClick={() => {
                  onNumberSelectChange(numb);
                }}
                sx={[style.numberButton, _selected && style.numberButtonPressed]}
                aria-selected={_selected}
              >
                <Typography
                  sx={[style.number, _selected && style.numberPressed]}
                >
                  {zeroFill(numb)}
                </Typography>
              </Fab>
            )
          })}
        </Box>

      </Box>

      {/* Popups  */}

      <ConfirmPopup
        open={openPopup_permutationEntering}
        onDismiss={onDismissPermutationEntering}
        dismissButtonText={t('cancel')}
        confirmButtonText={t('Grid.validate')}
        onConfirm={onConfirmPermutationEntering}
        type="info"
        title={t("Grid.permutations") + "?"}
      >
        {t("Grid.permutation_entering")}

      </ConfirmPopup>

      <AlertPopup
        open={openPopup_maxSelection}
        onClose={hideMaxSelectionPopup}
        title={t("Grid.permutations")}
        type="info"
      >
        {t('Grid.max_selections_reached', { max: maxSelectionCount })}

      </AlertPopup>
    </Box>
  )
}

//======================================
export default GridContent;